import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { differenceInSeconds } from 'date-fns';
import { getThemeColor, getThemeFont } from '@poly/admin-book';
import { alwaysNewDate, ensureIsDate } from '@poly/utils';
import { func, instanceOf, oneOfType, string } from 'prop-types';

const ResendCodeMessageWrapper = styled.div`
  color: ${getThemeColor(['midDark'])};
  font-family: ${getThemeFont(['defaultFont'])};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 40px;
  ${({ asButton }) =>
    !asButton ? '' : 'text-decoration: underline; cursor: pointer;'}
`;

// formatTimeToDisplay :: Int -> String
const formatTimeToDisplay = R.compose(
  R.join(':'),
  R.map(
    R.compose(
      R.when((num) => num.length < 2, R.concat('0')),
      R.toString,
    ),
  ),
  R.converge(R.pair, [
    R.pipe(R.divide(R.__, 60), Math.floor),
    R.modulo(R.__, 60),
  ]),
);

// prepareSecondsLeft :: DateTime -> Int
const prepareSecondsLeft = R.ifElse(
  R.isNil,
  R.always(0),
  R.compose(
    R.unless(R.gt(R.__, 0), R.always(0)),
    R.converge(differenceInSeconds, [ensureIsDate, alwaysNewDate]),
  ),
);

export function ResendCodeMessage({ codeExpiresAt, onEnterMobile }) {
  const [secondsLeft, setSecondsLeft] = useState(
    prepareSecondsLeft(codeExpiresAt),
  );

  useEffect(() => {
    setSecondsLeft(prepareSecondsLeft(codeExpiresAt));
  }, [codeExpiresAt]);

  useEffect(() => {
    let intervalId = null;

    if (secondsLeft > 0) {
      intervalId = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [secondsLeft]);

  if (secondsLeft === 0) {
    return (
      <ResendCodeMessageWrapper asButton onClick={onEnterMobile}>
        Resend Code
      </ResendCodeMessageWrapper>
    );
  }

  return (
    <ResendCodeMessageWrapper>
      You can resend code in {formatTimeToDisplay(secondsLeft)}
    </ResendCodeMessageWrapper>
  );
}

ResendCodeMessage.propTypes = {
  onEnterMobile: func,
  codeExpiresAt: oneOfType([string, instanceOf(Date)]),
};
