import React from 'react';
import { getThemeColor } from '@poly/admin-book';
import styled from 'styled-components';
import { node, string } from 'prop-types';
import { FlexColumnContainer } from '../components/Containers.js';
import { Icon } from '../icons/Icon.js';

const ContentContainer = styled(FlexColumnContainer)`
  height: 80%;
  padding: 50px 24px 24px 24px;
  padding-top: 50px;
  justify-content: space-between;
  box-sizing: border-box;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 20%;
  background-color: ${({ color }) => getThemeColor([color])};
  position: relative;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: ${({ color }) => getThemeColor([color])};
  border: 8px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
`;

export function StatusHeader({ color, icon }) {
  return (
    <HeaderContainer color={color}>
      <IconContainer color={color}>
        <Icon name={icon} />
      </IconContainer>
    </HeaderContainer>
  );
}

StatusHeader.propTypes = {
  color: string,
  icon: string,
};

const ScreenContainer = styled(FlexColumnContainer)`
  height: 100%;
`;

function StatusScreen({ children, color, icon }) {
  return (
    <ScreenContainer>
      <StatusHeader color={color} icon={icon} />
      <ContentContainer>{children}</ContentContainer>
    </ScreenContainer>
  );
}

StatusScreen.propTypes = {
  children: node.isRequired,
  color: string.isRequired,
  icon: string.isRequired,
};

export function SuccessScreen({ children }) {
  return (
    <StatusScreen color="success" icon="check">
      {children}
    </StatusScreen>
  );
}

SuccessScreen.propTypes = {
  children: node.isRequired,
};

export function WarningScreen({ children }) {
  return (
    <StatusScreen color="warning" icon="exclamationMark">
      {children}
    </StatusScreen>
  );
}

WarningScreen.propTypes = {
  children: node.isRequired,
};

export function ErrorScreen({ children }) {
  return (
    <StatusScreen color="error" icon="question">
      {children}
    </StatusScreen>
  );
}

ErrorScreen.propTypes = {
  children: node.isRequired,
};
