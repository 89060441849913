import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import { firstLettersOfEachWord } from '@poly/utils';
import { SYSTEM_USER_NAME } from '@poly/constants';

const UserAvatarComp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  flex-shrink: 0;
  background-color: #436cbe;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
`;

const PolyLogo = styled(UserAvatarComp).attrs({ children: 'P' })`
  background-color: #ff6363;
`;

// getUserName :: User -> String
export const getUserName = R.pathOr(SYSTEM_USER_NAME, ['fullName']);

export function UserAvatar({ user }) {
  if (!user) return null;

  const userName = getUserName(user);

  const avatar = firstLettersOfEachWord(userName);

  return userName === SYSTEM_USER_NAME ? (
    <PolyLogo />
  ) : (
    <UserAvatarComp>{avatar}</UserAvatarComp>
  );
}
UserAvatar.propTypes = { user: shape({ fullName: string }) };
