import React from 'react';
import * as R from 'ramda';
import { string, shape } from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from '@poly/client-routing';
import { insertParamsIntoURL } from '@poly/utils';

import { routes } from '../routes.js';
import { Icon } from '../icons/Icon.js';
import { Loader } from '../components/Loader.js';
import { InfinityList } from '../components/InfinityList.js';
import { NoResultsFoundScreen } from './NoResultsFoundScreen.js';
import { ProjectListCard } from '../components/ProjectListCard.js';
import { defaultProjectsFilterState } from '../redux/project/reducer.js';
import { ScreenListWrapper } from '../components/ScreenListWrapper/ScreenListWrapper.js';
import { screenListPropTypes } from '../components/ScreenListWrapper/screenListPropTypes.js';
import { SET_PROJECTS_FILTER } from '../redux/project/actions.js';
import { useSearchProjects } from '../hooks/useSearchProjects.js';
import { useSetListTotal } from '../hooks/useSetListTotal.js';

const changedFilterStyles = css`
  ::after {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #e75858;
    position: absolute;
    top: 2px;
    right: 2px;
  }
`;

const FilterIconWrapperS = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 10px;
  margin-left: 8px;
  position: relative;
  background-color: #fff;

  ${({ isFilterChanged }) => isFilterChanged && changedFilterStyles};
`;

const ProjectItemWrapperS = styled.div`
  display: grid;
  padding: 12px;
  box-sizing: border-box;
  background: #ffffff;
`;

function FilterIcon() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchProjectsFilter = useSelector(R.prop('searchProjectsFilter'));

  const isFilterChanged = !R.equals(
    searchProjectsFilter,
    defaultProjectsFilterState,
  );

  const onClick = () =>
    navigate(routes.projectFilter, { state: { previous: location } });

  return (
    <FilterIconWrapperS onClick={onClick} isFilterChanged={isFilterChanged}>
      <Icon name="filter" />
    </FilterIconWrapperS>
  );
}

function ProjectItemComponent({ project }) {
  const navigate = useNavigate();
  const location = useLocation();

  const projectDetailsUrl = insertParamsIntoURL(
    { projectId: project?._id },
    routes.projectDetail,
  );

  const onClick = () =>
    navigate(projectDetailsUrl, { state: { previous: location } });

  return (
    <ProjectItemWrapperS onClick={onClick}>
      <ProjectListCard project={project} />
    </ProjectItemWrapperS>
  );
}

ProjectItemComponent.propTypes = {
  project: shape({ projectId: string.isRequired }).isRequired,
};

// prepareQueryByProjectFilter :: (String, SearchProjectsFilter) -> ElasticQuery
const prepareQueryByProjectFilter = (clientId, filter) =>
  R.compose(
    R.ifElse(R.isEmpty, R.always(null), R.objOf('must')),
    R.map(R.objOf('match')),
    R.map(([key, value]) => ({ [key]: value })),
    R.toPairs,
    R.dissoc('sortingOption'),
    R.reject(R.isNil),
    R.mergeRight({ clientId }),
  )(filter);

function ProjectList({ sort, searchTerm, setTotal, entity }) {
  const isHistoryOpen = useSelector((state) => state.searchHistory.isOpen);
  const searchProjectsFilter = useSelector(R.prop('searchProjectsFilter'));
  const clientId = useSelector((state) => state.globalClient?.clientId);

  const query = prepareQueryByProjectFilter(clientId, searchProjectsFilter);

  const { loading, projects, listProps, total } = useSearchProjects(
    query,
    searchTerm,
    sort,
  );

  useSetListTotal(total, setTotal, loading);

  if (loading) return <Loader />;

  if (total === 0 && !isHistoryOpen) return <NoResultsFoundScreen />;

  return (
    <InfinityList
      entity={entity}
      items={projects}
      ItemComponent={ProjectItemComponent}
      {...listProps}
    />
  );
}

ProjectList.propTypes = screenListPropTypes;

export function ProjectListScreen() {
  const dispatch = useDispatch();

  const onSortHandler = (option) => {
    dispatch({ type: SET_PROJECTS_FILTER, payload: { sortingOption: option } });
  };

  const screenProps = {
    onSortHandler,
    List: ProjectList,
    entity: 'project',
    title: 'Projects list',
    Toolbar: <FilterIcon />,
  };

  return <ScreenListWrapper {...screenProps} />;
}
