import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { string, func, bool, shape } from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { getAssetModelsQuery, keywordSortQuery } from '@poly/client-utils';
import { ASC_SORT_ORDER } from '@poly/constants';
import { debounce } from '@poly/utils';

import { AsyncSelectWithSearch } from './Select/AsyncSelectWithSearch.js';
import { assetScannerIndexedDb } from '../offline/indexedDb/indexedDb.js';
import { assetModelsStoreName } from '../offline/indexedDb/indexedDbStores.js';
import { useNetworkStatus } from '../providers/NetworkStatusProvider.js';

const SEARCH_ASSET_MODEL_QUERY = gql`
  query SEARCH_ASSET_MODELS_QUERY($input: CollectionSearchParams) {
    searchAssetManufacturesModels(input: $input) {
      hits {
        _id
        name
        manufacturerId
      }
    }
  }
`;

// prepareAssetModelOptions :: AssetManufacturerQueryResult -> [Option]
const prepareAssetModelOptions = R.compose(
  R.map(R.applySpec({ label: R.prop('name'), value: R.prop('_id') })),
  R.sortBy(R.prop('name')),
);

const useAssetModelFromIndexedDb = (manufacturerId, isOnline) => {
  const [assetModels, setAssetModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isOnline && manufacturerId) {
      const getAssetModels = async () => {
        setIsLoading(true);
        const iDb = await assetScannerIndexedDb();
        const models = await iDb.getByIndex(
          assetModelsStoreName,
          'manufacturerId',
          IDBKeyRange.only(manufacturerId),
        );

        setAssetModels(models);
        setIsLoading(false);
      };
      getAssetModels();
    }
  }, [manufacturerId]);

  return { cashedAssetModels: assetModels, isLoading };
};

const useAssetModelWithCache = (manufacturerId, searchTerm) => {
  const { isOnline } = useNetworkStatus();

  const { data, loading } = useQuery(SEARCH_ASSET_MODEL_QUERY, {
    variables: {
      input: {
        searchTerm,
        size: 1000,
        query: getAssetModelsQuery(manufacturerId),
        sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
      },
    },
    fetchPolicy: 'network-only',
    skip: !manufacturerId || !isOnline,
  });

  const { cashedAssetModels, isLoading } = useAssetModelFromIndexedDb(
    manufacturerId,
    isOnline,
  );

  const assetModels = isOnline
    ? R.pathOr([], ['searchAssetManufacturesModels', 'hits'], data)
    : cashedAssetModels;

  return { assetModels, loading: loading || isLoading };
};

export function AssetModelSelect({
  onChange,
  hasError,
  formSpyProps,
  ...props
}) {
  const {
    form,
    values: { manufacturerId },
  } = formSpyProps;

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = useCallback(
    debounce(300)((search) => {
      setSearchTerm(search);

      if (search) {
        form.change('searchedModel', search);
      }
    }),
    [],
  );

  const { assetModels, loading } = useAssetModelWithCache(
    manufacturerId,
    searchTerm,
  );
  const options = prepareAssetModelOptions(assetModels);

  return (
    <AsyncSelectWithSearch
      {...props}
      isClearable
      showConsole
      options={options}
      hasError={hasError}
      isLoading={loading}
      onChange={onChange}
      isDisabled={!manufacturerId}
      onInputChange={debouncedSearch}
      placeholder="Select Asset Model"
    />
  );
}

AssetModelSelect.propTypes = {
  hasError: bool,
  onChange: func.isRequired,
  formSpyProps: shape({
    values: shape({
      manufacturerId: string,
    }).isRequired,
  }).isRequired,
};
