import * as R from 'ramda';
import styled from 'styled-components';
import React, { useRef, useEffect } from 'react';
import { string, bool, func, shape, arrayOf } from 'prop-types';
import ReactSelect, { components } from 'react-select';
import { getThemeColor } from '@poly/admin-book';
import { propEqLegacy } from '@poly/utils';

import { inputStyles } from '../Input.js';
import { Icon } from '../../icons/Icon.js';

const { Option } = components;

function CustomOption(props) {
  const { isSelected, children } = props;

  return (
    <Option {...props}>
      {children}
      {isSelected && <Icon name="checked" />}
    </Option>
  );
}

CustomOption.propTypes = {
  children: string.isRequired,
  isSelected: bool.isRequired,
};

const AsyncSelectWithSearchS = styled(ReactSelect)`
  .search-select__control {
    ${inputStyles};

    flex-direction: row;
    padding: 8px 12px 8px 12px;
  }

  .search-select__single-value {
    color: ${getThemeColor(['primaryLight'])};
  }

  .search-select__option {
    padding: 14px 20px;
  }
  .search-select__indicator-separator {
    display: none;
  }

  .search-select__indicator {
    position: absolute;
    right: 5%;

    & > svg {
      width: 16px;
      height: 16px;
      fill: ${getThemeColor(['primaryDark'])};
    }
  }

  .search-select__clear-indicator {
    position: absolute;
    right: 10%;
  }

  .search-select__option,
  .search-select__placeholder,
  .search-select__single-value {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: ${getThemeColor(['primaryDark'])};
  }

  .search-select__placeholder {
    color: ${getThemeColor(['scaleMid'])};
  }

  .search-select__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search-select__option--is-selected {
    color: #014398;
  }

  .search-select__option:active,
  .search-select__option--is-focused,
  .search-select__option--is-selected {
    background-color: transparent;
  }

  .search-select__control,
  .search-select__control:hover,
  .search-select__control:focus,
  .search-select__control--is-focused {
    min-height: 32px;
    box-shadow: unset;
    border: 1px solid #fff !important;
    border-radius: 0;
  }

  .search-select__menu {
    border: none;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    width: 100%;
    left: 0;

    z-index: 2;
  }

  .search-select__control--is-disabled {
    background: #ededed !important;
  }
`;

// getPreparedValue :: String -> [Option] -> Option
const getPreparedValue = (value) => R.find(propEqLegacy('value', value));

// changeSelectValue :: Function -> Option -> _
const changeSelectValue = (changeHandler) =>
  R.compose(changeHandler, R.prop('value'));

export function AsyncSelectWithSearch({ value, options, onChange, ...props }) {
  const selectRef = useRef(null);

  const preparedValue = getPreparedValue(value)(options);

  // we need this one for the correct form.change field logic work
  useEffect(() => {
    if (!value && selectRef?.current?.state?.value) {
      selectRef?.current?.select?.clearValue();
    }
  }, [value]);

  return (
    <AsyncSelectWithSearchS
      {...props}
      ref={selectRef}
      options={options}
      value={preparedValue}
      classNamePrefix="search-select"
      components={{ Option: CustomOption }}
      onChange={changeSelectValue(onChange)}
      key={`uniq_select_key_${R.prop('value', props)}`}
    />
  );
}

AsyncSelectWithSearch.propTypes = {
  value: string,
  options: arrayOf(shape({ value: string, label: string })),
  onChange: func.isRequired,
};
