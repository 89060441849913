import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape, arrayOf } from 'prop-types';
import { formatDateTime, isNilOrEmpty, forceTitleCase } from '@poly/utils';
import {
  tokenizeMessageByMentions,
  UpdateSentEmailsPreview,
  isEmailFile,
} from '@poly/client-utils';

import { FileLink } from '../../components/FileLink.js';
import { InfinityList } from '../../components/InfinityList.js';
import { UpdateEmailData } from '../../components/UpdateEmailData.js';
import { BorderBottomContainer } from '../../components/Containers.js';
import { ProjectDetailsTabWrapperS } from './ProjectDetailsAssetsTab.js';
import { UserAvatar, getUserName } from '../../components/UserAvatar.js';
import { useProjectUpdates } from './useProjectUpdates.js';

const UpdateListItemWrapperS = styled(BorderBottomContainer)`
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
`;

const UpdateListItemInfoS = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 36px);
  margin-left: 14px;

  a {
    color: #436cbe;
    font-size: 12px;
    line-height: 18px;
  }
`;

const UpdateListItemHeaderS = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const UpdateListItemUserNameS = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #000;
  line-height: 20px;
`;

const UpdateListItemDateTimeS = styled.div`
  display: flex;
  flex-shrink: 0;
  font-size: 10px;
  font-weight: 500;
  color: #999;
  line-height: 14px;
`;

const UpdateListItemTitleS = styled(UpdateListItemUserNameS)`
  font-size: 12px;
  line-height: 18px;
  margin-top: 6px;
`;

const UpdateListItemTextS = styled(UpdateListItemDateTimeS)`
  font-size: 12px;
  line-height: 18px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-x: hidden;
`;

const UpdateListItemMentionS = styled.span`
  color: #ff6363;
`;

const UpdateSentEmailsPreviewS = styled(UpdateSentEmailsPreview)`
  font-size: 12px;
  line-height: 18px;
  margin-top: 6px;
`;

function UpdateListItem({ update }) {
  const {
    title,
    emailData,
    createdAt,
    createdBy,
    message = '',
    mentions = {},
    attachments = [],
  } = update;

  const emailFiles = R.filter(isEmailFile, attachments);
  const restFiles = R.without(emailFiles, attachments);

  return (
    <UpdateListItemWrapperS>
      <UserAvatar user={createdBy} />
      <UpdateListItemInfoS>
        <UpdateListItemHeaderS>
          <UpdateListItemUserNameS>
            {getUserName(createdBy)}
          </UpdateListItemUserNameS>
          <UpdateListItemDateTimeS>
            {formatDateTime(createdAt)}
          </UpdateListItemDateTimeS>
        </UpdateListItemHeaderS>
        <UpdateListItemTitleS>{forceTitleCase(title)}</UpdateListItemTitleS>
        {!!emailData && <UpdateEmailData {...emailData} />}
        <UpdateListItemTextS>
          {tokenizeMessageByMentions(message, mentions).map(
            ({ text, isMention }) =>
              isMention ? (
                <UpdateListItemMentionS key={text}>
                  {text}
                </UpdateListItemMentionS>
              ) : (
                text
              ),
          )}
        </UpdateListItemTextS>
        {!isNilOrEmpty(restFiles) &&
          restFiles.map((file) => <FileLink key={file.url} {...file} />)}
        <UpdateSentEmailsPreviewS emailFiles={emailFiles} />
      </UpdateListItemInfoS>
    </UpdateListItemWrapperS>
  );
}

UpdateListItem.propTypes = {
  update: shape({
    title: string,
    message: string,
    createdAt: string.isRequired,
    createdBy: shape({ fullName: string }),
    emailData: shape({ subject: string.isRequired }),
    attachments: arrayOf(shape({ url: string.isRequired })),
    mentions: shape({
      groups: arrayOf(shape({ name: string.isRequired })),
      users: arrayOf(shape({ fullName: string.isRequired })),
    }),
  }),
};

export function ProjectDetailsUpdatesTab({ projectId }) {
  const { updates, loading, listProps } = useProjectUpdates(projectId);

  if (loading) return null;

  return (
    <ProjectDetailsTabWrapperS>
      <InfinityList
        items={updates}
        entity="update"
        ItemComponent={UpdateListItem}
        {...listProps}
      />
    </ProjectDetailsTabWrapperS>
  );
}

ProjectDetailsUpdatesTab.propTypes = { projectId: string.isRequired };
