import * as R from 'ramda';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import React, { useCallback, useRef, useEffect } from 'react';
import { func, shape, string, oneOfType } from 'prop-types';
import { getThemeColor } from '@poly/admin-book';
import {
  MAX_FILE_SIZE_ERROR_MESSAGE,
  MAX_FILE_NAME_CHARS_LIMIT,
  MAX_FILE_SIZE,
} from '@poly/constants';
import {
  getExtensionByFileName,
  isFileExtensionAllowed,
  debounce,
} from '@poly/utils';

import { Text } from './Text.js';
import { Image } from './Image.js';
import { Icon } from '../icons/Icon.js';
import { useThemeColor } from '../hooks/useThemeColor.js';

const AttachPhotoWrapperS = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const AttachPhotoInputS = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -27px;
  right: 0;
  align-items: center;
`;

const AttachPhotoLabelS = styled(Text)`
  color: ${getThemeColor(['secondaryDark'])};
  font-size: 14px;
  margin-left: 10px;
`;

export const RemovePhotoIconS = styled.div`
  display: flex;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 3px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 10px;
  height: 10px;
  align-items: center;
  justify-content: center;
`;

// validateIfNewFile :: Validator -> File -> Boolean
const validateIfNewFile = (validator) =>
  R.ifElse(
    R.both(R.complement(R.isNil), R.propSatisfies(R.isNil, '_id')),
    validator,
    R.T,
  );

export const attachPhotoFormValidators = (
  validatorWrapperFn = validateIfNewFile,
) => [
  [
    validatorWrapperFn(
      R.compose(R.lt(R.__, MAX_FILE_SIZE), R.propOr(0, 'size')),
    ),
    MAX_FILE_SIZE_ERROR_MESSAGE,
  ],
  [
    validatorWrapperFn(
      R.compose(R.complement(R.isEmpty), R.trim, R.propOr('', 'name')),
    ),
    'File name is required',
  ],
  [
    validatorWrapperFn(
      R.compose(isFileExtensionAllowed, getExtensionByFileName, R.prop('name')),
    ),
    'File extension is not allowed',
  ],
  [
    validatorWrapperFn(
      R.compose(R.gte(MAX_FILE_NAME_CHARS_LIMIT), R.length, R.prop('name')),
    ),
    `Maximum file name length is ${MAX_FILE_NAME_CHARS_LIMIT} characters`,
  ],
];

export function AttachPhotoInput({ value, onChange }) {
  const attachedPhotoRef = useRef(null);
  const iconColor = useThemeColor(['secondaryDark']);

  const scrollIntoViewDebounced = useCallback(
    debounce(200)(() => {
      if (attachedPhotoRef) {
        attachedPhotoRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }),
    [],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (images) => {
      const imageUpload = Object.assign(images[0], {
        preview: URL.createObjectURL(images[0]),
      });
      onChange(imageUpload);

      if (attachedPhotoRef) {
        scrollIntoViewDebounced();
      }
    },
  });

  useEffect(() => {
    if (value && !value?.preview) {
      const image = Object.assign(value, {
        preview: URL.createObjectURL(value),
      });
      onChange(image);
    }
  }, [value]);

  return (
    <AttachPhotoWrapperS>
      {!value && (
        <AttachPhotoInputS {...getRootProps()}>
          <Icon name="attachImage" color={iconColor} width={16} />
          <AttachPhotoLabelS>Add photo</AttachPhotoLabelS>
          <input {...getInputProps()} />
        </AttachPhotoInputS>
      )}
      {value?.preview && <Image src={value.preview} ref={attachedPhotoRef} />}
      {value && (
        <RemovePhotoIconS onClick={() => onChange(null)}>
          <Icon name="close" width={16} color="#fff" />
        </RemovePhotoIconS>
      )}
    </AttachPhotoWrapperS>
  );
}

AttachPhotoInput.propTypes = {
  onChange: func.isRequired,
  value: oneOfType([string, shape({ preview: string })]),
};
