import React from 'react';
import { Form } from 'react-final-form';
import { useMutation, gql } from '@apollo/client';
import { string, shape, func, bool } from 'prop-types';
import { useLocation, useNavigate } from '@poly/client-routing';
import { insertParamsIntoURL } from '@poly/utils';
import { useUploadAttachment } from '@poly/client-utils/src/files/useUploadAttachment.js';

import { FormS } from './styles.js';
import { routes } from '../../routes.js';
import { editAssetFormId } from './constants.js';
import { AssetFormFields } from './AssetFormFields.js';
import {
  validateAssetFormQRCode,
  prepareAssetForFormInitialValues,
  prepareAssetFormValuesToEditMutation,
} from './assetFormHelpers.js';

const UPDATE_ASSET_MUTATION = gql`
  mutation UPDATE_ASSET_MUTATION($id: ID!, $input: UpdateAssetInput) {
    updateAsset(id: $id, input: $input) {
      asset {
        _id
        qrCodeId
      }
    }
  }
`;

function FormRendered({ handleSubmit, values }) {
  return (
    <FormS id={editAssetFormId} onSubmit={handleSubmit}>
      <AssetFormFields values={values} />
    </FormS>
  );
}

FormRendered.propTypes = {
  handleSubmit: func.isRequired,
  values: shape({ withNewMake: bool, withNewModel: bool }).isRequired,
};

export function EditAssetForm({ asset, setInProcess }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [updateAsset] = useMutation(UPDATE_ASSET_MUTATION);
  const uploadFile = useUploadAttachment();

  const onSubmit = async (formData) => {
    const input = prepareAssetFormValuesToEditMutation(formData);

    setInProcess(true);

    let uploadedPhotoId = null;

    if (formData?.photo instanceof File) {
      uploadedPhotoId = await uploadFile(formData?.photo);
    }

    await updateAsset({
      variables: {
        id: asset._id,
        input: {
          ...input,
          ...(uploadedPhotoId ? { uploadedPhotoId } : {}),
        },
      },
    });

    setInProcess(false);

    const assetDetailUrl = insertParamsIntoURL(
      { assetId: asset._id },
      routes.assetDetail,
    );

    navigate(assetDetailUrl, { state: { previous: location } });
  };

  const initialValues = prepareAssetForFormInitialValues(asset);

  return (
    <Form
      onSubmit={onSubmit}
      render={FormRendered}
      initialValues={initialValues}
      validate={validateAssetFormQRCode}
    />
  );
}

EditAssetForm.propTypes = {
  setInProcess: func.isRequired,
  asset: shape({ _id: string.isRequired }).isRequired,
};
