import React from 'react';
import { RoutesGenerator } from '@poly/client-routing';

import { routes } from './routes.js';
import { HelpScreen } from './screens/HelpScreen.js';
import { BaseLayout } from './layouts/BaseLayout.js';
import { LoginScreen } from './screens/Login/LoginScreen.js';
import { LoginLayout } from './layouts/LoginLayout.js';
import { CommonLayout } from './layouts/CommonLayout.js';
import { HomeScreen } from './screens/HomeScreen/HomeScreen.js';
import { NavigationLayout } from './layouts/NavigationLayout.js';
import { ProjectListScreen } from './screens/ProjectListScreen.js';
import { RequestConfirmedScreen } from './screens/ProjectCreated.js';
import { CreateProjectScreen } from './screens/CreateProjectScreen.js';
import { SubmitProjectScreen } from './screens/SubmitProjectScreen.js';
import { PropertyAssetsScreen } from './screens/PropertyAssetsScreen.js';
import { NotFoundProjectScreen } from './screens/NotFoundProjectScreen.js';
import { ConfirmSupplierCheckInScreen } from './screens/SupplierCheckIn/SelectSupplierScreen.js';
import { SupplierCheckInSelectProjectScreen } from './screens/SupplierCheckIn/SelectProjectScreen.js';
import { ExistingProjectScreen } from './screens/ExistingProjectScreen.js';
import { EditAssetScreen } from './screens/AssetForm/EditAssetScreen.js';
import { AssetDetailScreen } from './screens/AssetDetailScreen/AssetDetailScreen.js';
import { OnboardingScreen } from './screens/Onboarding.js';
import { ScanQrCodeScreen } from './screens/ScanQrCode/ScanQrCode.js';
import { AddAssetScreen } from './screens/AssetForm/AddAssetScreen.js';
import { CheckInSuccessScreen } from './screens/SupplierCheckIn/CheckInSuccessScreen.js';
import { AddAssetSuccessScreen } from './screens/AssetForm/AddAssetSuccess.js';
import { ScanQrCodeErrorScreen } from './screens/ScanQrCode/ErrorScreen.js';
import { AddProjectUpdateScreen } from './screens/AddProjectUpdateScreen.js';
import { ProjectFilterScreen } from './screens/ProjectFilterScreen/ProjectFilterScreen.js';
import { ProjectDetailsScreen } from './screens/ProjectDetailsScreen/ProjectDetailsScreen.js';
import { AssetsQueueScreen } from './screens/AssetsQueueScreen/AssetsQueueScreen.js';
import { EditAssetQueueScreen } from './screens/EditAssetQueueScreen/EditAssetQueueScreen.js';
import { ScanQrCodeInactiveScreen } from './screens/ScanQrCode/InactiveScreen.js';
import { CreateWorkOrderScreen } from './screens/CreateWorkOrderScreen.js';
import { WorkOrderCreatedScreen } from './screens/WorkOrderCreatedScreen.js';
import { ForbiddenProjectCreationScreen } from './screens/ForbiddenProjectCreationScreen.js';
import { ProcedureStepsScreen } from './screens/SupplierCheckIn/ProcedureStepsScreen.js';
import { AssetsProceduresQueueScreen } from './screens/SupplierCheckIn/AssetsProceduresQueueScreen.js';
import { InactivePropertyScreen } from './screens/InactivePropertyScreen.js';

const routerConfig = [
  {
    layout: LoginLayout,
    routes: [
      [routes.help, HelpScreen],
      [routes.login, LoginScreen],
      [routes.inactiveProperty, InactivePropertyScreen],
      ['/*', HelpScreen],
    ],
  },
  {
    layout: BaseLayout,
    routes: [
      [routes.createProject, CreateProjectScreen],
      [routes.submitProject, SubmitProjectScreen],
      [routes.projectCreated, RequestConfirmedScreen],
      [routes.existingProject, ExistingProjectScreen],
      [routes.supplierCheckIn, SupplierCheckInSelectProjectScreen],
      [routes.supplierCheckInConfirm, ConfirmSupplierCheckInScreen],
      [routes.assetProcedureSteps, ProcedureStepsScreen],
      [routes.createWorkOrder, CreateWorkOrderScreen],
      [routes.workOrderCreatedSuccess, WorkOrderCreatedScreen],
      [routes.notFoundProject, NotFoundProjectScreen],
    ],
  },
  {
    layout: NavigationLayout,
    routes: [
      [routes.home, HomeScreen],
      [routes.projects, ProjectListScreen],
      [routes.propertyAssets, PropertyAssetsScreen],
    ],
  },
  {
    layout: CommonLayout,
    routes: [
      [routes.addAsset, AddAssetScreen],
      [routes.editAsset, EditAssetScreen],
      [routes.assetDetail, AssetDetailScreen],
      [routes.projectFilter, ProjectFilterScreen],
      [routes.projectDetail, ProjectDetailsScreen],
      [routes.addProjectUpdate, AddProjectUpdateScreen],
      [routes.checkInSuccess, CheckInSuccessScreen],
      [routes.scanQrCode, ScanQrCodeScreen],
      [routes.scanQrCodeError, ScanQrCodeErrorScreen],
      [routes.scanQrCodeInactive, ScanQrCodeInactiveScreen],
      [routes.addAssetSuccess, AddAssetSuccessScreen],
      [routes.onboarding, OnboardingScreen],
      [routes.assetsQueue, AssetsQueueScreen],
      [routes.editAssetsQueue, EditAssetQueueScreen],
      [routes.forbiddenProjectCreation, ForbiddenProjectCreationScreen],
      [routes.supplierCheckInQueue, AssetsProceduresQueueScreen],
    ],
  },
];

export function Router() {
  return <RoutesGenerator config={routerConfig} />;
}
