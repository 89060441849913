import React from 'react';
import styled from 'styled-components';

import { Text } from '../components/Text.js';
import { FlexColumnCentered } from '../components/Containers.js';
import { ScreenHeader } from '../components/ScreenHeader.js';
import { Navigation } from '../components/Navigation.js';
import { Icon } from '../icons/Icon.js';

const Container = styled(FlexColumnCentered)`
  flex-grow: 1;
  padding: 60px;
  justify-content: center;
`;

export const TextContainer = styled(FlexColumnCentered)`
  span {
    margin-bottom: 12px;
    text-align: center;
  }
`;

const TitleText = styled(Text)`
  font-weight: 700;
  padding-top: 14px;
`;

const ScreenHeaderS = styled(ScreenHeader)`
  background-color: #fff;
  border-bottom: 2px solid #ebebeb;
`;

export function NotFoundScreen() {
  return (
    <>
      <ScreenHeaderS goBack title="Resource not found" />
      <Container>
        <Icon name="noResults" />
        <TextContainer>
          <TitleText size={18}>404 Not Found</TitleText>
          <Text size={14}>
            Not sure how you got here but we are unable to find this resource.
          </Text>
        </TextContainer>
      </Container>
      <Navigation />
    </>
  );
}
