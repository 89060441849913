import React from 'react';
import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';
import { useParams } from '@poly/client-routing';

import { routes } from '../routes.js';
import { Loader } from '../components/Loader.js';
import { AssetsList } from './HomeScreen/AssetsList.js';
import { ScreenListWrapper } from '../components/ScreenListWrapper/ScreenListWrapper.js';

const propertyQuery = gql`
  query propertyQuery($propertyId: ID!) {
    property(id: $propertyId) {
      _id
      name
    }
  }
`;

export function PropertyAssetsScreen() {
  const { propertyId } = useParams();

  const { data, loading } = useQuery(propertyQuery, {
    variables: { propertyId },
    skip: !propertyId,
  });

  if (loading) return <Loader />;

  const propertyName = R.pathOr('', ['property', 'name'], data);

  const screenProps = {
    goBack: true,
    entity: 'asset',
    List: AssetsList,
    route: routes.home,
    listProps: { propertyId },
    title: `${propertyName} Assets list`,
  };

  return <ScreenListWrapper {...screenProps} />;
}
