import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useLocation, useNavigate } from '@poly/client-routing';
import { useSelector } from 'react-redux';

import { Icon } from '../icons/Icon.js';
import { routes } from '../routes.js';
import { QueueSyncStatuses } from './useEntitySyncStatusQueue.js';
import { useHasPermissionToCreateEditAsset } from '../hooks/useHasPermissionToCreateEditAsset.js';

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: ${R.prop('background')};
  padding: 0 24px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background-color: ${R.prop('iconBackground')};
  border-radius: 50%;
  align-self: ${({ isSubtitle }) => (isSubtitle ? 'flex-start' : 'center')};
`;

const ButtonS = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 6px;
  width: 88px;
  height: 43px;
  color: #fff;
  border: none;
  background-color: ${R.prop('buttonBackground')};
  margin-left: 35px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

const SyncTitle = styled.p`
  margin: 0;
  padding: 0%;
  color: ${R.prop('mainColor')};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const SyncSubTitle = styled.p`
  margin: 0;
  padding: 0%;
  font-family: 'TTNormsRegular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  color: ${R.prop('mainColor')};
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 43px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const syncStatusConfig = {
  [QueueSyncStatuses.PENDING]: {
    background: '#174198',
    iconName: 'offline',
    mainColor: '#fff',
    buttonBackground: 'rgba(246, 249, 253, 0.40)',
    iconBackground: '#fff',
    iconColor: '#174198',
    getTitle: () => 'You are offline',
    getSubTitle: (n) => `${n} item(s) in the queue`,
  },
  [QueueSyncStatuses.SYNCING]: {
    background: '#FFB841',
    iconName: 'upload',
    mainColor: '#000',
    buttonBackground: 'rgba(26, 21, 20, 0.40)',
    getTitle: (entityName, n) => `Syncing ${n} Pending ${entityName}`,
  },
  [QueueSyncStatuses.FAILED]: {
    background: '#E75858',
    iconName: 'warning',
    mainColor: '#fff',
    buttonBackground: 'rgba(246, 249, 253, 0.40)',
    getTitle: (entityName, n) => `${n} ${entityName} failed to sync`,
  },
  [QueueSyncStatuses.DRAFT]: {
    background: '#FFB841',
    iconName: 'upload',
    mainColor: '#000',
    buttonBackground: 'rgba(26, 21, 20, 0.40)',
    getTitle: (entityName) => `You have unsaved ${R.toLower(entityName)}`,
    getSubTitle: (n) => `${n} item(s) in the queue`,
  },
};

// isNotAllowedRouteToShowQueue :: String -> Boolean
const isNotAllowedRouteToShowQueue = (route) =>
  R.includes(route, [
    routes.assetsQueue,
    routes.assetProcedureSteps,
    routes.supplierCheckInQueue,
  ]);

export function SyncAssetsQueueStatus() {
  const navigate = useNavigate();

  const hasPermissionToSubmitAsset = useHasPermissionToCreateEditAsset();

  const entityName = hasPermissionToSubmitAsset ? 'Assets' : 'Services';

  const queuePageRoute = hasPermissionToSubmitAsset
    ? routes.assetsQueue
    : routes.supplierCheckInQueue;

  const location = useLocation();

  const handleClick = () => navigate(queuePageRoute);

  const { status, itemsCount } = useSelector((state) => state.syncQueueStatus);

  const config = syncStatusConfig[status];

  if (!config || isNotAllowedRouteToShowQueue(location.pathname)) {
    return null;
  }

  const {
    background,
    iconName,
    mainColor,
    buttonBackground,
    getTitle,
    getSubTitle,
    iconBackground,
    iconColor,
  } = config;

  return (
    <Wrapper background={background}>
      <FlexWrapper>
        <IconWrapper
          iconBackground={iconBackground || mainColor}
          isSubtitle={!!getSubTitle}
        >
          <Icon name={iconName} color={iconColor} height="16" width="16" />
        </IconWrapper>
        <TextWrapper>
          <SyncTitle mainColor={mainColor}>
            {getTitle(entityName, itemsCount)}
          </SyncTitle>
          {getSubTitle && (
            <SyncSubTitle mainColor={mainColor}>
              {getSubTitle(itemsCount)}
            </SyncSubTitle>
          )}
        </TextWrapper>
      </FlexWrapper>
      {location.pathname !== routes.addAsset && (
        <ButtonS buttonBackground={buttonBackground} onClick={handleClick}>
          <Icon name="arrowUpRight" /> Queue
        </ButtonS>
      )}
    </Wrapper>
  );
}

export const useSyncAssetsQueueStatusHeight = () => {
  const { status } = useSelector((state) => state.syncQueueStatus);

  const location = useLocation();

  return status && location.pathname !== routes.assetsQueue ? 80 : 0;
};
