import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import { WorkOrderStatus } from '@poly/constants';

import { Icon } from '../icons/Icon.js';

export const StatusContainerS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${R.prop('background')};
  padding: 0 7px;
  height: 28px;
  gap: 7px;
`;

export const ColorTextS = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${R.prop('color')};
`;

const commonOnHoldConfig = {
  color: '#9A9DA0',
  background: '#F0F0F0',
  text: 'On hold',
  iconName: 'pause',
};

const projectStatusesConfig = {
  [WorkOrderStatus.ACTIVE]: {
    color: '#1C84C6',
    background: '#ddedf6',
    text: 'Active',
    iconName: 'triangle',
  },
  [WorkOrderStatus.COMPLETED]: {
    color: '#1AB394',
    background: '#ddf4ef',
    text: 'Completed',
    iconName: 'complete',
  },
  [WorkOrderStatus.PENDING]: {
    color: '#F5AD20',
    background: '#fdebc7',
    text: 'Pending',
    iconName: 'pending',
  },
  [WorkOrderStatus.BLOCKED]: {
    color: '#C34444',
    background: '#ffc3c3',
    text: 'Blocked',
    iconName: 'blocked',
  },
  [WorkOrderStatus.ON_HOLD_CLIENT]: commonOnHoldConfig,
  [WorkOrderStatus.ON_HOLD_PARTS]: commonOnHoldConfig,
  [WorkOrderStatus.ON_HOLD_PROJECT]: commonOnHoldConfig,
};

export function ProjectStatus({ status }) {
  const config = projectStatusesConfig[status];
  if (!config) {
    return null;
  }

  const { color, text, background, iconName } = config;
  return (
    <StatusContainerS background={background}>
      <Icon name={iconName} />
      <ColorTextS color={color}>{text}</ColorTextS>
    </StatusContainerS>
  );
}

ProjectStatus.propTypes = { status: string.isRequired };
