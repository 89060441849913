import React from 'react';
import { node, string } from 'prop-types';
import { getThemeColor, getThemeFont } from '@poly/admin-book';
import styled from 'styled-components';
import { Icon } from '../icons/Icon.js';

const LoginLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 180px 26px 26px 26px;
  box-sizing: border-box;
`;

const LogoContainer = styled.div`
  width: 100%;
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
`;

const LogoTitle = styled.span`
  font-family: ${getThemeFont(['defaultFont'])};
  color: ${getThemeColor(['primaryRegular'])};
  font-size: 32px;
  text-align: center;
  font-weight: normal;
  line-height: 38px;
  margin-bottom: 42px;
`;

const LoginPageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  box-sizing: border-box;
  position: relative;
`;

export function LoginLayout({ justifyContent, children }) {
  return (
    <LoginLayoutContainer>
      <LogoContainer>
        <Icon name="logo" width={69} height={69} />
      </LogoContainer>
      <LogoTitle>Poly Scanner</LogoTitle>
      <LoginPageContentContainer justifyContent={justifyContent}>
        {children}
      </LoginPageContentContainer>
    </LoginLayoutContainer>
  );
}

LoginLayout.propTypes = {
  children: node.isRequired,
  justifyContent: string,
};

LoginLayout.defaultProps = {
  justifyContent: 'flex-end',
};
