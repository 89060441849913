import {
  func,
  shape,
  string,
  number,
  object,
  arrayOf,
  oneOfType,
  instanceOf,
} from 'prop-types';
import styled from 'styled-components';
import { VariableSizeList } from 'react-window';
import { useDispatch, useSelector } from 'react-redux';
import VirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import React, { memo, useRef, useEffect, useState } from 'react';
import ReactWindowInfiniteLoader from 'react-window-infinite-loader';
import { debounce } from '@poly/utils';

import { SET_LIST_SCROLL } from '../redux/listScroll/actions.js';

const rowDataPropTypes = shape({
  ItemComponent: oneOfType([func, object]),
  entity: string.isRequired,
  items: arrayOf(
    shape({
      _id: string,
    }).isRequired,
  ),
  setRowHeight: func.isRequired,
  gap: number.isRequired,
});

function VariableSizeListComponent({ listRef, row, rowData, ...props }) {
  return (
    <VariableSizeList {...props} ref={listRef} itemData={rowData}>
      {row}
    </VariableSizeList>
  );
}

VariableSizeListComponent.propTypes = {
  listRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
  row: oneOfType([func, object]).isRequired,
  rowData: rowDataPropTypes,
};

function RowComponentBase({ index, style, data }) {
  const { ItemComponent, itemKey, items, entity, setRowHeight, gap } = data;

  const ref = useRef();
  useEffect(() => {
    const handleResize = debounce(100)(() => {
      if (ref.current) {
        setRowHeight(index, ref.current.clientHeight + gap);
      }
    });

    window.addEventListener('resize', handleResize);

    if (ref.current) {
      setRowHeight(index, ref.current.clientHeight + gap);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const entityData = items[index];
  if (!entityData) {
    return null;
  }
  const rowProps = {
    [entity]: entityData,
    key: `${entityData[itemKey]}-${index}`,
    index,
    setRowHeight,
  };

  return (
    <div style={style}>
      <div ref={ref}>
        <ItemComponent {...rowProps} />
      </div>
    </div>
  );
}

RowComponentBase.propTypes = {
  index: number.isRequired,
  style: shape({
    top: number,
  }),
  data: rowDataPropTypes,
};

const RowComponent = memo(RowComponentBase);

const InfinityListWrapper = styled.div`
  height: 100%;
  width: 100%;

  .list-auto-sizer > div {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export function InfinityList({
  gap,
  items,
  entity,
  itemKey,
  threshold,
  itemCount,
  loadMoreItems,
  ItemComponent,
}) {
  let scrollTimer;
  const dispatch = useDispatch();
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollOptions, setScrollOptions] = useState(null);
  const { direction, offset } = useSelector((state) => state.listScroll);

  const isItemLoaded = (idx) => idx < items.length;

  const emulatedItemCount =
    itemCount > items.length ? items.length + 1 : items.length;

  const listRef = useRef(null);

  const sizeMap = useRef({});

  const setRowHeight = (index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(0);
  };

  const getItemSize = (index) => sizeMap.current[index] || 100;

  const onScroll = ({ scrollDirection, scrollOffset }) => {
    setScrollOptions({ scrollDirection, scrollOffset });

    if (!isScrolling) {
      setIsScrolling(true);
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (!isScrolling && !!scrollOptions) {
      const { scrollDirection, scrollOffset } = scrollOptions;
      const offsetDiff = Math.abs(scrollOffset - offset);
      const isBottomScrolled =
        offsetDiff < 150 && scrollDirection === 'backward';

      if (
        scrollDirection !== direction &&
        offsetDiff !== 0 &&
        !isBottomScrolled
      ) {
        dispatch({
          type: SET_LIST_SCROLL,
          payload: { direction: scrollDirection, offset: scrollOffset },
        });
      }
    }
  }, [isScrolling, scrollOptions]);

  return (
    <InfinityListWrapper>
      <VirtualizedAutoSizer className="list-auto-sizer">
        {({ height, width }) => (
          <ReactWindowInfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={emulatedItemCount}
            loadMoreItems={loadMoreItems}
            threshold={threshold}
          >
            {({ onItemsRendered, ref }) => (
              <VariableSizeListComponent
                listRef={(list) => {
                  ref(list);
                  listRef.current = list;
                }}
                height={height}
                width={width}
                itemCount={emulatedItemCount}
                itemSize={getItemSize}
                onItemsRendered={onItemsRendered}
                rowData={{
                  ItemComponent,
                  items,
                  entity,
                  setRowHeight,
                  gap,
                  itemKey,
                }}
                row={RowComponent}
                onScroll={onScroll}
                useIsScrolling
              />
            )}
          </ReactWindowInfiniteLoader>
        )}
      </VirtualizedAutoSizer>
    </InfinityListWrapper>
  );
}

InfinityList.propTypes = {
  entity: string.isRequired,
  loadMoreItems: func.isRequired,
  threshold: number.isRequired,
  itemCount: number.isRequired,
  ItemComponent: oneOfType([func, object]),
  items: arrayOf(
    shape({
      _id: string,
    }).isRequired,
  ),
  gap: number,
  itemKey: string,
};

InfinityList.defaultProps = {
  gap: 16,
  itemKey: '_id',
};
