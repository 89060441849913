import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { removeFileExtension } from '@poly/utils';

import { Icon } from '../icons/Icon.js';

const FileLinkWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;

  > a {
    margin-left: 6px;
  }
`;

export function FileLink({ url, fileName }) {
  return (
    <FileLinkWrapperS>
      <Icon name="sendTo" width="20px" height="20px" />
      <a rel="noopener noreferrer" target="_blank" href={url}>
        {removeFileExtension(fileName)}
      </a>
    </FileLinkWrapperS>
  );
}

FileLink.propTypes = { url: string.isRequired, fileName: string.isRequired };
