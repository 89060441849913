import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { ApolloProvider } from '@apollo/client';
import { func, node, oneOfType } from 'prop-types';
import { createApolloClientWitchPersistCache } from '@poly/apollo-client';
import { useUserLogout } from '@poly/client-utils';
import { history } from '@poly/client-routing';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

import { routes, PUBLIC_ROUTES } from '../routes.js';

// There is no centralized way to report errors
// to user in this app yet:
const handleApolloError = R.identity;

// shouldLogout :: String -> Boolean
const shouldLogout = R.complement(R.includes(R.__, PUBLIC_ROUTES));

export function ApolloClientProvider({ children }) {
  const [apolloClient, setApolloClient] = useState(null);

  const logOutUser = useUserLogout();

  const onTokenHasExpired = () => {
    if (shouldLogout(history.location.pathname)) {
      logOutUser({
        localStorage,
        loginRoute: routes.login,
        routeParams: { from: history.location },
        storageItemsToRemove: ['searchHistory'],
      });
    }
  };

  useEffect(() => {
    const initApolloClient = async () => {
      const client = await createApolloClientWitchPersistCache({
        storage: new LocalStorageWrapper(window.localStorage),
        redirectPageHandler: onTokenHasExpired,
        handleApolloError,
        persistCache,
      });

      setApolloClient(client);
    };
    initApolloClient();
  }, []);

  if (!apolloClient) {
    return null;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

ApolloClientProvider.propTypes = {
  children: oneOfType([node, func]).isRequired,
};
