import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useEntityMultipleSubscription } from '@poly/client-utils';
import {
  getAssetManufacturerSubscriptionOptions,
  getAssetManufacturerSubscriptionOptionsByQrCode,
  getAssetSubscriptionOptions,
  getAssetSubscriptionOptionsByQrCode,
} from '@poly/client-utils/src/asset.js';

const assetDetailsFields = gql`
  fragment assetDetailsFields on Asset {
    _id
    type {
      _id
      name
      replacementCost
    }
    model
    serial
    status
    location
    property {
      _id
      status
    }
    warrantyEnd
    newModelName
    newManufacturerName
    manufacturerDoc {
      _id
      name
      replacementCost
    }
    description
    qrCodeId
    photo {
      url
      fileName
    }
    activeProjects {
      projectId
      suppliers {
        supplierId
        supplierName
      }
    }
    modelDoc {
      _id
      name
      replacementCost
    }
    equipmentType
    commissioningDate
    purchasePrice
    replacementCost
    client {
      configs {
        assetScannerApp {
          allowReplacementCost
          allowProjectCreation
        }
        projectPriorities {
          id
          priority
          amount
          unit
        }
      }
    }
  }
`;

const assetQuery = gql`
  query asset($id: ID!) {
    asset(id: $id) {
      ...assetDetailsFields
    }
  }

  ${assetDetailsFields}
`;

const assetQueryByQrCodeId = gql`
  query assetByQrCodeId($input: AssetByQrCodeIdInput!) {
    assetByQrCodeId(input: $input) {
      ...assetDetailsFields
    }
  }

  ${assetDetailsFields}
`;

const assetSubscription = gql`
  subscription assetSubscription($input: SingleDocSubInput!) {
    assetChanged(input: $input) {
      id
      document {
        ...assetDetailsFields
      }
    }
  }

  ${assetDetailsFields}
`;

export const assetManufacturersSubscription = gql`
  subscription assetManufacturersSubscription($input: CollectionSearchParams) {
    searchAssetManufacturerChanged(input: $input) {
      id
      type
    }
  }
`;

export const useAssetDetails = (assetId) => {
  const queryOptions = {
    variables: { id: assetId },
    fetchPolicy: 'network-only',
    skip: !assetId,
  };

  const { data, loading, error } = useEntityMultipleSubscription(
    assetQuery,
    [assetSubscription, assetManufacturersSubscription],
    {
      queryOptions,
      getSubscriptionOptions: [
        getAssetSubscriptionOptions,
        getAssetManufacturerSubscriptionOptions,
      ],
    },
  );

  const asset = R.pathOr({}, ['asset'], data);

  return { asset, loading, errorMessage: error?.message };
};

export const useAssetDetailsByQrCode = (qrCodeId) => {
  const queryOptions = {
    variables: { input: { qrCodeId } },
    fetchPolicy: 'network-only',
    skip: !qrCodeId,
  };

  const { data, loading, error } = useEntityMultipleSubscription(
    assetQueryByQrCodeId,
    [assetSubscription, assetManufacturersSubscription],
    {
      queryOptions,
      getSubscriptionOptions: [
        getAssetSubscriptionOptionsByQrCode,
        getAssetManufacturerSubscriptionOptionsByQrCode,
      ],
    },
  );

  const asset = R.pathOr({}, ['assetByQrCodeId'], data);

  return { asset, loading, errorMessage: error?.message };
};
