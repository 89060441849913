import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { func, string } from 'prop-types';
import { getThemeColor } from '@poly/admin-book';

import { Icon } from '../icons/Icon.js';
import { useModalContext } from '../providers/ModalProvider.js';
import { useNetworkStatus } from '../providers/NetworkStatusProvider.js';
import { Button } from '../components/Button.js';

const offlineModalId = 'offlineModalId';

const IS_NOT_SHOW_OFFLINE_SUPPORT_MESSAGE_KEY =
  'isNotShowOfflineSupportMessage';

const ModalWrapper = styled.div`
  position: relative;
  min-width: 296px;
  min-height: 509px;
  margin: 135px 47px;
  background-color: #fff;
`;

const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background-color: #174198;
  justify-content: flex-end;
`;

const IconS = styled(Icon)`
  padding: 5px;
  cursor: pointer;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 55px 14px 38px 14px;
`;

const ModalTitle = styled.div`
  color: #12347a;
  font-family: 'TTNormsMedium';
  font-size: 22px;
  font-weight: 500;
`;

const Content = styled(ModalTitle)`
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 20px 0;
`;

const CheckLabel = styled.div`
  color: #999;
  font-family: 'TTNormsMedium';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const CheckBoxInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 14px;
  height: 14px;
  border: 1px solid #bcbcbc;
  border-radius: 2px;
  display: grid;
  place-content: center;
  :before {
    content: '';
    width: 10px;
    height: 10px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    box-shadow: inset 1em 1em ${getThemeColor(['secondaryDark'])};
    background-color: ${getThemeColor(['secondaryDark'])};
  }
  :checked::before {
    transform: scale(1);
  }
`;

const OfflineIconWrapper = styled.div`
  box-sizing: border-box;
  padding: 8px;
  position: absolute;
  width: 70px;
  height: 70px;
  background-color: #fff;
  border-radius: 50%;
  top: 40px;
  left: calc(50% - 35px);
  filter: drop-shadow(0px -5px 15px rgba(0, 0, 0, 0.2));
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  background-color: #174198;
  border-radius: 50%;
`;

function OfflineModalComp({ handleClose, offlineSupportMgs }) {
  const [isNotShow, setIsNotShow] = useState(false);

  const handleChange = () => setIsNotShow(!isNotShow);

  const handleClick = () => {
    if (isNotShow) {
      localStorage.setItem(IS_NOT_SHOW_OFFLINE_SUPPORT_MESSAGE_KEY, true);
    }

    handleClose();
  };

  return (
    <ModalWrapper>
      <OfflineIconWrapper>
        <IconWrapper>
          <Icon name="offline" />
        </IconWrapper>
      </OfflineIconWrapper>
      <ModalHeader>
        <IconS name="modalClose" color="#fff" onClick={handleClose} />
      </ModalHeader>
      <ModalContentWrapper>
        <ModalTitle>Pardon for interruption</ModalTitle>
        <Content>
          Your device lost Internet connection. However, this app can still be
          used to {offlineSupportMgs}. All data will be stored locally while
          you&#39;re offline. Please, don&#39;t forget to open an app when
          you&#39;ll get good WiFi connection. <br /> <br />
          Visit Queue page to check syncing status of your actions.
        </Content>
        <CheckBoxWrapper>
          <CheckBoxInput
            value={isNotShow}
            onChange={handleChange}
            type="checkbox"
          />
          <CheckLabel>Don&#39;t show this message again</CheckLabel>
        </CheckBoxWrapper>
        <Button
          caption="Understood"
          iconName="like"
          iconSize={20}
          onClick={handleClick}
        />
      </ModalContentWrapper>
    </ModalWrapper>
  );
}

OfflineModalComp.propTypes = {
  handleClose: func.isRequired,
  offlineSupportMgs: string.isRequired,
};

export const useOfflineModal = ({
  hasPermissionToSubmitAsset,
  hasAccessToCheckInProject,
}) => {
  const { openModal, closeModal } = useModalContext();

  const hasPermission = hasPermissionToSubmitAsset || hasAccessToCheckInProject;

  const offlineSupportMgs = hasPermissionToSubmitAsset
    ? 'create new assets'
    : ' servicing projects';

  const isNotShow = localStorage.getItem(
    IS_NOT_SHOW_OFFLINE_SUPPORT_MESSAGE_KEY,
  );

  const { isOnline } = useNetworkStatus();

  const handleClose = () => closeModal(offlineModalId);

  useEffect(() => {
    if (hasPermission && !isNotShow && !isOnline) {
      openModal({
        id: offlineModalId,
        content: (
          <OfflineModalComp
            handleClose={handleClose}
            offlineSupportMgs={offlineSupportMgs}
          />
        ),
      });
    }
  }, [isOnline]);
};
