import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { ensureIsDate } from '@poly/utils';
import { string } from 'prop-types';
import { format } from 'date-fns';
import { Text, getThemeColor } from '@poly/admin-book';
import { useSelector } from 'react-redux';

import {
  IconButtonWrapperS,
  NavigateIconButton,
  TextButton,
} from '../../screens/HomeScreen/AssetsList.js';
import { Icon } from '../../icons/Icon.js';
import { assetScannerIndexedDb } from '../../offline/indexedDb/indexedDb.js';
import { publishIdbStoreChangedEvent } from '../../offline/indexedDb/indexedDbStoreChangeEvent.js';
import { EntityQueueStatus } from './EntityQueueStatus.js';
import { EntityQueueIcon } from './EntityQueueIcon.js';
import { CachedEntitySyncStatuses } from '../../offline/cache/helpers.js';

const FlexWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
  width: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 55px);
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const EntityQueueItemCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 76px;
  background-color: #fff;
  padding: 8px;
`;

const EntityQueueDateTime = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #5e6271;
  align-self: flex-end;
`;

const TitleS = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${getThemeColor(['primaryDark2'])};
  word-break: break-all;
`;

// getEntityFormatDateTime :: {createdAt: Date, updatedAt: Date} -> String
const getEntityFormatDateTime = R.compose(
  R.when(R.is(Date), (date) => format(date, 'h:mm aa  MM/dd/yyyy')),
  ensureIsDate,
  R.ifElse(R.prop('updatedAt'), R.prop('updatedAt'), R.prop('createdAt')),
);

const FailedLabel = styled(Text)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const FailedText = styled(FailedLabel)`
  color: #5e6271;
  margin-bottom: 12px;
`;

const ButtonsContainer = styled(FlexWrapper)`
  height: 42px;
  gap: 10px;
`;

const RemoveButtonWrapper = styled(IconButtonWrapperS)`
  width: 100%;
  background-color: #e75858;
`;

const RemoveTextButton = styled(TextButton)`
  color: #fff;
`;

function RemoveEntityBtn({ idbKey, indexedDbStoreName }) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    if (isDeleting) {
      return;
    }
    setIsDeleting(true);
    const iDb = await assetScannerIndexedDb();
    await iDb.deleteById(indexedDbStoreName, idbKey);
    setIsDeleting(false);
    publishIdbStoreChangedEvent();
  };

  return (
    <RemoveButtonWrapper onClick={handleDelete} disable={isDeleting}>
      <Icon name="remove" />
      <RemoveTextButton>Remove</RemoveTextButton>
    </RemoveButtonWrapper>
  );
}

RemoveEntityBtn.propTypes = {
  idbKey: string.isRequired,
  indexedDbStoreName: string.isRequired,
};

const EditButtonS = styled(NavigateIconButton)`
  width: 100%;
`;

export function EntityQueueItemBase({
  syncStatus,
  idbKey,
  title,
  navigateUrl,
  indexedDbStoreName,
  ...entity
}) {
  const { isSyncing } = useSelector((state) => state.syncQueueStatus);

  const isPending = syncStatus === CachedEntitySyncStatuses.PENDING;

  const isSuccess = syncStatus === CachedEntitySyncStatuses.SUCCESS;

  const isDraft = syncStatus === CachedEntitySyncStatuses.DRAFT;

  const isFailed = syncStatus === CachedEntitySyncStatuses.FAILED;

  const buttonProps = {
    text: isPending ? 'Edit' : 'Edit and Retry',
    name: isSuccess ? 'edit' : 'retry',
    to: navigateUrl,
    disable: isSyncing,
  };

  const time = getEntityFormatDateTime(entity);

  const isAllowToRemove = isDraft || isFailed;

  return (
    <EntityQueueItemCard>
      <FlexWrapper>
        <EntityQueueIcon syncStatus={syncStatus} />
        <FlexColumn>
          <FlexSpaceBetween>
            <EntityQueueDateTime>{time}</EntityQueueDateTime>
            <EntityQueueStatus syncStatus={syncStatus} />
          </FlexSpaceBetween>
          <TitleS>{title}</TitleS>
        </FlexColumn>
      </FlexWrapper>
      {entity.errorMsg && (
        <>
          <FailedLabel>Failed reason:</FailedLabel>
          <FailedText>{entity.errorMsg}</FailedText>
        </>
      )}
      <ButtonsContainer>
        {!isSuccess && <EditButtonS {...buttonProps} />}
        {isAllowToRemove && (
          <RemoveEntityBtn
            idbKey={idbKey}
            indexedDbStoreName={indexedDbStoreName}
          />
        )}
      </ButtonsContainer>
    </EntityQueueItemCard>
  );
}

EntityQueueItemBase.propTypes = {
  syncStatus: string.isRequired,
  idbKey: string.isRequired,
  title: string.isRequired,
  navigateUrl: string.isRequired,
  indexedDbStoreName: string.isRequired,
};
