import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { func, shape, string, arrayOf } from 'prop-types';
import { propEqLegacy } from '@poly/utils';

import { Image } from './Image.js';
import { Icon } from '../icons/Icon.js';
import { RemovePhotoIconS } from './AttachPhotoInput.js';
import { useThemeColor } from '../hooks/useThemeColor.js';

const MultipleFilesUploadWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MultipleFilesDropZoneS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(100% - 2px);
  height: 40px;
  background: #fff;
  border: 1px dashed ${R.prop('color')};
  border-radius: 3px;

  > svg {
    margin-right: 7px;
  }
`;

const MultipleFilesDropZoneTextS = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999;

  > span {
    margin-left: 3px;
    color: #12347a;
  }
`;

const MultipleFilesAttachmentsRowS = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const MultipleFilesAttachmentWrapperS = styled.div`
  display: flex;
  height: 122px;
  max-width: 122px;
  position: relative;
  margin-top: 8px;

  &:not(:last-child) {
    margin-right: 8px;
  }

  > img {
    height: 100%;
  }
`;

// prepareAttachments :: [Upload] -> [Upload] -> [Upload]
const prepareAttachmentsOnDrop = (existedAttachments) =>
  R.compose(
    R.take(5),
    R.uniqBy(R.prop('name')),
    R.concat(existedAttachments),
    R.map((attachment) =>
      Object.assign(attachment, {
        preview: URL.createObjectURL(attachment),
      }),
    ),
  );

// prepareAttachmentsOnRemove :: String -> [Upload] -> [Upload]
const prepareAttachmentsOnRemove = (name) =>
  R.reject(propEqLegacy('name', name));

export function MultipleFilesUpload({
  value,
  onChange,
  reinitializeValidation,
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: (attachments) => {
      const preparedAttachments = prepareAttachmentsOnDrop(value)(attachments);
      onChange(preparedAttachments);
    },
  });

  const dropzoneText = 'Drag & Drop files here or';

  const iconColor = useThemeColor(['midDark']);
  const borderColor = useThemeColor(['midLight']);
  const activeColor = useThemeColor(['secondaryDark']);

  const getColor = (defaultColor) =>
    isDragActive ? activeColor : defaultColor;

  const onImageRemove = (name) => {
    const preparedAttachments = prepareAttachmentsOnRemove(name)(value);
    onChange(preparedAttachments);

    if (reinitializeValidation) {
      reinitializeValidation();
    }
  };

  return (
    <MultipleFilesUploadWrapperS>
      <MultipleFilesDropZoneS {...getRootProps()} color={getColor(borderColor)}>
        <Icon name="cloud" color={getColor(iconColor)} />
        <MultipleFilesDropZoneTextS>
          {dropzoneText}
          <span>Upload files</span>
        </MultipleFilesDropZoneTextS>
        <input {...getInputProps()} />
      </MultipleFilesDropZoneS>
      <MultipleFilesAttachmentsRowS>
        {value.map((file) => {
          const { preview, name } = file;

          const imageSrc = preview || URL.createObjectURL(new Blob([file]));

          return (
            <MultipleFilesAttachmentWrapperS key={name}>
              <Image src={imageSrc} />
              <RemovePhotoIconS onClick={() => onImageRemove(name)} />
            </MultipleFilesAttachmentWrapperS>
          );
        })}
      </MultipleFilesAttachmentsRowS>
    </MultipleFilesUploadWrapperS>
  );
}

MultipleFilesUpload.propTypes = {
  onChange: func.isRequired,
  reinitializeValidation: func,
  value: arrayOf(shape({ fileName: string })),
};
