import styled from 'styled-components';
import React, { useState } from 'react';
import * as R from 'ramda';
import { useParams, useNavigate } from '@poly/client-routing';

import { editAssetFormId } from './constants.js';
import { EditAssetForm } from './EditAssetForm.js';
import { Button } from '../../components/Button.js';
import { Loader } from '../../components/Loader.js';
import { useAssetDetails } from '../../hooks/useAssetDetails.js';
import { FormContainer, FormFooterContainer } from './styles.js';
import { NotFoundScreen } from '../NotFoundScreen.js';

const SubmitButtonS = styled(Button)`
  margin: 0;

  svg {
    margin-right: 10px;

    path {
      stroke: #fff;
    }
  }
`;

export function EditAssetScreen() {
  const navigate = useNavigate();
  const { assetId } = useParams();
  const [inProcess, setInProcess] = useState(false);

  const { asset, loading } = useAssetDetails(assetId);

  const onCloseForm = () => navigate(-1);

  if (loading) {
    return <Loader />;
  }

  if (R.isEmpty(asset)) {
    return <NotFoundScreen />;
  }

  return (
    <>
      <FormContainer
        title="Edit Asset Details"
        onClose={onCloseForm}
        footerHeight={160}
      >
        <EditAssetForm asset={asset} setInProcess={setInProcess} />
      </FormContainer>
      <FormFooterContainer>
        <SubmitButtonS
          type="submit"
          iconName="checked"
          form={editAssetFormId}
          loading={loading || inProcess}
          caption="Update Asset Details"
        />
      </FormFooterContainer>
    </>
  );
}
