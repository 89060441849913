import * as R from 'ramda';
import { useState } from 'react';
import { keywordSortQuery, commonSortQuery } from '@poly/client-utils';
import { ASC_SORT_ORDER, DESC_SORT_ORDER } from '@poly/constants';

const invertedSortOrderValues = ['createdAt', 'startDate', 'endDate'];
const commonSortValues = [...invertedSortOrderValues, 'status', 'priority'];

// getSortQuery :: String -> (() => SortQuery)
const getSortQuery = R.ifElse(
  R.includes(R.__, commonSortValues),
  R.always(commonSortQuery),
  R.always(keywordSortQuery),
);

// getSortOrder :: String -> String
const getSortOrder = R.ifElse(
  R.includes(R.__, invertedSortOrderValues),
  R.always(DESC_SORT_ORDER),
  R.always(ASC_SORT_ORDER),
);

export const useSortLogic = (defaultSortingOption) => {
  const [sortingOption, setSortingOption] = useState(defaultSortingOption);

  const sortQuery = getSortQuery(sortingOption);

  const order = getSortOrder(sortingOption);

  const sort = sortQuery(R.split('.', sortingOption))(order);

  return { sort, sortingOption, setSortingOption };
};
