import React, { useState } from 'react';
import styled from 'styled-components';
import { useOldVersionDetector } from '@poly/client-utils';

import { Button } from './Button.js';

const OldVersionNotification = styled.div`
  background-color: #ffd000;
  text-align: center;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReloadButtonS = styled(Button)`
  width: 200px;
  background-color: #f99500;
  color: black;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  border-radius: 4px;
`;

const OldVersionMessage = styled.span`
  margin-right: 20px;
`;

export function ScannerOldVersionDetector() {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  const notifyUserAboutNewVersion = () => setIsNewVersionAvailable(true);

  const reloadPage = useOldVersionDetector(notifyUserAboutNewVersion);

  return isNewVersionAvailable ? (
    <OldVersionNotification>
      <OldVersionMessage>
        New app version is available. Please refresh the page.
      </OldVersionMessage>
      <ReloadButtonS caption="Reload" onClick={reloadPage} />
    </OldVersionNotification>
  ) : null;
}
