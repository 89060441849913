import React from 'react';
import styled from 'styled-components';
import { func, string } from 'prop-types';
import { useNavigate, useRouterQuery } from '@poly/client-routing';
import { insertQueryParamsIntoURL } from '@poly/utils';

import { routes } from '../../routes.js';
import { Icon } from '../../icons/Icon.js';
import { Text } from '../../components/Text.js';
import { StatusHeader } from '../StatusScreen.js';
import { Button } from '../../components/Button.js';
import { ContactPhoneLink } from '../../components/PhoneLink.js';
import { FlexColumnCentered } from '../../components/Containers.js';

export const Container = styled.div`
  width: 80%;
  height: 380px;
  position: absolute;
  top: 25%;
  left: 10%;
  background: #fff;
  z-index: 2;
`;

export const Content = styled(FlexColumnCentered)`
  height: 80%;
  padding: 55px 15px 25px 15px;
  box-sizing: border-box;
`;

export const CloseIcon = styled(Icon).attrs({
  name: 'close',
  color: '#fff',
  width: 17,
  height: 17,
})`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const TextS = styled(Text)`
  max-width: 450px;
`;

export function AddNewConfirm({ onClose, assetQrCodeId }) {
  const navigate = useNavigate();
  const { propertyId } = useRouterQuery(['propertyId']);

  const onConfirm = () =>
    navigate(
      insertQueryParamsIntoURL(
        { assetQrCodeId, ...(propertyId ? { propertyId } : {}) },
        routes.addAsset,
      ),
    );

  return (
    <Container>
      <StatusHeader color="error" icon="question" />
      <CloseIcon onClick={onClose} />
      <Content>
        <TextContainer>
          <Text size={22} align="center">
            New QR Code Found
          </Text>
          <TextS size={14} align="center" margin="12px 0">
            If you would like to register this code please click the button
            below. Otherwise if you were not expecting this message please
            contact us
          </TextS>
        </TextContainer>
        <Button caption="Add New Asset" iconName="plus" onClick={onConfirm} />
        <ContactPhoneLink size={14} color="secondaryDark" />
      </Content>
    </Container>
  );
}

AddNewConfirm.propTypes = {
  onClose: func.isRequired,
  assetQrCodeId: string.isRequired,
};
