import React from 'react';
import { parseQuery, useLocation } from '@poly/client-routing';

import { Text } from '../components/Text.js';
import {
  FlexColumnCentered,
  FlexColumnSpaceBetween,
} from '../components/Containers.js';
import { PhoneButtonLink } from '../components/PhoneLink.js';
import { SuccessScreen } from './StatusScreen.js';
import { GoHomeBtn } from '../components/Button.js';

export function RequestConfirmedScreen() {
  const location = useLocation();
  const { projectId } = parseQuery(location.search);

  return (
    <SuccessScreen>
      <FlexColumnSpaceBetween>
        <FlexColumnCentered>
          <Text size={22} color="primaryDark">
            Request Confirmed
          </Text>
          <Text size={14} color="mainDark" margin="12px 0">
            Request #{projectId}
          </Text>
          <Text size={16} color="primaryDark" align="center">
            Thank you for submitting your request. If you have any questions you
            can reach us by phone.
          </Text>
        </FlexColumnCentered>
        <FlexColumnCentered>
          <GoHomeBtn inverted={false} caption="Go To Home Page" />
          <PhoneButtonLink inverted />
        </FlexColumnCentered>
      </FlexColumnSpaceBetween>
    </SuccessScreen>
  );
}
