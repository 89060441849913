import { useCallback, useMemo, useEffect } from 'react';
import { useSubscription, gql } from '@apollo/client';
import { debounce, tryCallFunction } from '@poly/utils';

import { useNetworkStatus } from '../../providers/NetworkStatusProvider.js';

const ASSET_MODELS_SUBSCRIPTION = gql`
  subscription ASSET_MODELS_SUBSCRIPTION($input: CollectionSearchParams) {
    searchAssetModelChanged(input: $input) {
      id
      type
    }
  }
`;

export const useSynchronizeCacheData = (hasPermission, isAllowedTab) => {
  const { isOnline } = useNetworkStatus();

  const shouldRunWorker = useMemo(
    () => isOnline && hasPermission,
    [isOnline, hasPermission],
  );

  const callSyncAssetModelsWorker = () => {
    if (hasPermission && isAllowedTab) {
      const synchronizeAssetModelsWorker = new Worker(
        new URL('./synchronizeAssetModelsWorker.js', import.meta.url),
      );

      synchronizeAssetModelsWorker.postMessage({});
      synchronizeAssetModelsWorker.onmessage = () => {
        synchronizeAssetModelsWorker.terminate();
      };
    }
  };

  const debouncedRefetch = useCallback(
    debounce(2000)(() => tryCallFunction(callSyncAssetModelsWorker)()),
    [hasPermission, isAllowedTab],
  );

  useSubscription(ASSET_MODELS_SUBSCRIPTION, {
    shouldResubscribe: true,
    onData: debouncedRefetch,
    variables: { input: {} },
    skip: !hasPermission,
  });

  useEffect(() => {
    if (shouldRunWorker) {
      callSyncAssetModelsWorker();
    }
  }, [shouldRunWorker, isAllowedTab]);
};
