import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import { AssetStatuses } from '@poly/constants';

import { useStateInputForInfiniteListQuery } from '../../hooks/useStateInputForInfiniteListQuery.js';

const SEARCH_ASSETS_LIST = gql`
  query SEARCH_ASSETS_LIST($input: CollectionSearchParams!) {
    searchAssets(input: $input) {
      hits {
        _id
        type {
          _id
          name
        }
        qrCodeId
        newManufacturerName
        manufacturerDoc {
          _id
          name
        }
        photo {
          url
        }
        newModelName
        modelDoc {
          _id
          name
        }
      }
      total
    }
  }
`;

export const useSearchAssets = (propertyId, searchTerm, sort) => {
  const input = useStateInputForInfiniteListQuery(sort, searchTerm, {
    bool: {
      must: [
        { terms: { status: [AssetStatuses.ACTIVE, AssetStatuses.PENDING] } },
        ...(propertyId ? [{ match: { propertyId } }] : []),
      ],
    },
  });

  const {
    data,
    loading,
    tableProps: listProps,
  } = useTableInfiniteScrollQuery(SEARCH_ASSETS_LIST, input, {
    endpointName: 'searchAssets',
    pageSize: 25,
  });

  const assets = R.pathOr([], ['searchAssets', 'hits'], data);

  const total = R.pathOr(0, ['searchAssets', 'total'], data);

  return {
    listProps,
    assets,
    total,
    loading,
  };
};
