import React from 'react';
import * as R from 'ramda';
import { string, bool, func } from 'prop-types';
import { gql } from '@apollo/client';
import { useReactiveQuery } from '@poly/client-utils/src/index.js';

import { AsyncSelectWithSearch } from './Select/AsyncSelectWithSearch.js';

const SEARCH_ASSET_TYPE_QUERY = gql`
  query SEARCH_ASSET_TYPE_QUERY {
    assetTypes {
      _id
      name
    }
  }
`;

const ASSET_TYPES_CHANGED_SUBSCRIPTION = gql`
  subscription ASSET_TYPES_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchAssetTypeChanged(input: $input) {
      id
      type
    }
  }
`;

// formatAssetTypeOptions :: AssetTypesQueryResult -> [Option]
const formatAssetTypeOptions = R.compose(
  R.map(R.applySpec({ label: R.prop('name'), value: R.prop('_id') })),
  R.defaultTo([]),
  R.prop('assetTypes'),
);

export const useAssetTypes = (skip = false) => {
  const { data, loading } = useReactiveQuery(
    SEARCH_ASSET_TYPE_QUERY,
    ASSET_TYPES_CHANGED_SUBSCRIPTION,
    {
      queryOptions: {
        fetchPolicy: 'cache-and-network',
        skip,
      },
      subscriptionOptions: { variables: { input: {} }, skip },
    },
  );

  return { data, loading };
};

export function AssetTypeSelect({ value, onChange, hasError }) {
  const { data, loading } = useAssetTypes();

  const options = formatAssetTypeOptions(data);

  return (
    <AsyncSelectWithSearch
      value={value}
      options={options}
      isLoading={loading}
      hasError={hasError}
      onChange={onChange}
      placeholder="Select Asset Type"
    />
  );
}

AssetTypeSelect.propTypes = {
  value: string,
  hasError: bool,
  onChange: func.isRequired,
};
