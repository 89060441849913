import React, { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import * as R from 'ramda';

import { isNilOrEmpty } from '@poly/utils';
import {
  useUpdateQueryParams,
  useRouterQuery,
  useNavigate,
} from '@poly/client-routing';
import { compressImage } from '@poly/client-utils/src/files/compressImage.js';

import { routes } from '../../routes.js';
import { assetScannerIndexedDb } from '../../offline/indexedDb/indexedDb.js';
import { assetsStoreName } from '../../offline/indexedDb/indexedDbStores.js';
import { CachedEntitySyncStatuses } from '../../offline/cache/helpers.js';
import { useModalContext } from '../../providers/ModalProvider.js';
import {
  ErrorModal,
  assetQueueErrorModalId,
} from '../EditAssetQueueScreen/ErrorModal.js';
import { useAssetFromIndexedDb } from '../EditAssetQueueScreen/useAssetFromIndexedDb.js';

const compressAssetPhotoP = async (asset) => {
  if (asset?.photo) {
    const photo = await compressImage(asset?.photo);
    return { ...asset, photo };
  }

  return Promise.resolve(asset);
};

const saveAssetToIndexedDbP = async (iDb, input) => {
  const asset = await compressAssetPhotoP(input);
  return iDb.create(assetsStoreName, {
    ...asset,
    syncStatus: CachedEntitySyncStatuses.DRAFT,
    createdAt: new Date(),
    idbKey: uuidV4(),
  });
};

const updateAssetByIdbKeyP = async (iDb, idbKey, input) => {
  const asset = await compressAssetPhotoP(input);

  await iDb.update(assetsStoreName, asset, idbKey);
};

const isAssetWithQrCodeNotExistP = async (iDb, qrCodeId) => {
  const asset = await iDb.getByIndex(
    assetsStoreName,
    'qrCodeId',
    IDBKeyRange.only(qrCodeId),
  );

  return isNilOrEmpty(asset);
};

// getErrorMessage :: String -> String
const getErrorMessage = R.when(
  R.both(R.is(String), R.test(/bloburls are not yet supported/i)),
  R.always(
    'Apple is limiting our capability to store asset images in private browsing mode. Please, use regular browsing mode to avoid this issue',
  ),
);

export const useAddAssetForLogic = () => {
  const { assetQrCodeId, propertyId, isPreview, idbKey } = useRouterQuery([
    'assetQrCodeId',
    'propertyId',
    'isPreview',
    'idbKey',
  ]);

  const { asset: assetDraft, loading: assetLoading } = useAssetFromIndexedDb(
    idbKey,
    [isPreview],
  );

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const updateQueryParams = useUpdateQueryParams();
  const togglePreview = (key) =>
    updateQueryParams({ isPreview: !isPreview, idbKey: key });

  const { openModal, closeModal } = useModalContext();

  const handleClose = () => closeModal(assetQueueErrorModalId);

  const handlePreview = async (formData) => {
    const showErrorModal = (errMsg) =>
      openModal({
        id: assetQueueErrorModalId,
        content: (
          <ErrorModal
            errorMsg={errMsg}
            handleClose={handleClose}
            goHome={() => navigate(routes.home)}
          />
        ),
      });

    try {
      setLoading(true);
      const iDb = await assetScannerIndexedDb();
      const isNotExist = await isAssetWithQrCodeNotExistP(
        iDb,
        formData.qrCodeId,
      );

      if (isNotExist && !assetDraft?.idbKey) {
        const assetIdbKey = await saveAssetToIndexedDbP(iDb, formData);
        togglePreview(assetIdbKey);
      } else if (assetDraft?.idbKey) {
        await updateAssetByIdbKeyP(iDb, assetDraft?.idbKey, formData);
        togglePreview(assetDraft?.idbKey);
      } else {
        showErrorModal(
          `Asset with QR Code ${formData.qrCodeId} already exists`,
        );
      }
    } catch (e) {
      showErrorModal(getErrorMessage(e.message));
    } finally {
      setLoading(false);
    }
  };

  return {
    handlePreview,
    assetLoading,
    loading,
    isPreview,
    assetQrCodeId,
    propertyId,
    assetDraft,
  };
};
