import * as R from 'ramda';
import { gql } from '@apollo/client';
import { func, bool, shape } from 'prop-types';
import React, { useState, useCallback } from 'react';
import {
  ASC_SORT_ORDER,
  ELASTIC_RESULT_WINDOW_MAX_SIZE,
} from '@poly/constants';
import { keywordSortQuery, useReactiveQuery } from '@poly/client-utils';
import { debounce } from '@poly/utils';

import { AsyncSelectWithSearch } from './Select/AsyncSelectWithSearch.js';
import { assetManufacturersSubscription } from '../hooks/useAssetDetails.js';

const SEARCH_ASSET_MAKE_QUERY = gql`
  query SEARCH_ASSET_MAKE_QUERY($input: CollectionSearchParams) {
    searchAssetManufactures(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;

// prepareAssetMakeOptions :: AssetManufacturersQueryResult -> [Option]
const prepareAssetMakeOptions = R.compose(
  R.map(R.applySpec({ label: R.prop('name'), value: R.prop('_id') })),
  R.pathOr([], ['searchAssetManufactures', 'hits']),
);

export const useAssetsMakes = (searchTerm, skip = false) => {
  const { data, loading } = useReactiveQuery(
    SEARCH_ASSET_MAKE_QUERY,
    assetManufacturersSubscription,
    {
      queryOptions: {
        variables: {
          input: {
            searchTerm,
            size: ELASTIC_RESULT_WINDOW_MAX_SIZE,
            sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
          },
        },
        fetchPolicy: 'cache-and-network',
        skip,
      },
      subscriptionOptions: { variables: { input: {} }, skip },
    },
  );

  return { data, loading };
};

export function AssetMakeSelect({
  onChange,
  hasError,
  formSpyProps,
  ...props
}) {
  const { form } = formSpyProps;

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = useCallback(
    debounce(300)((search) => {
      setSearchTerm(search);

      if (search) {
        form.change('searchedMake', search);
      }
    }),
    [],
  );

  const { data, loading } = useAssetsMakes(searchTerm);

  const options = prepareAssetMakeOptions(data);

  const onAssetMakeChange = (make) => {
    onChange(make);
    form.change('modelId', null);
  };

  return (
    <AsyncSelectWithSearch
      options={options}
      hasError={hasError}
      isLoading={loading}
      onChange={onAssetMakeChange}
      onInputChange={debouncedSearch}
      placeholder="Select Asset Make"
      {...props}
    />
  );
}

AssetMakeSelect.propTypes = {
  hasError: bool,
  onChange: func.isRequired,
  formSpyProps: shape({
    form: shape({ change: func.isRequired }).isRequired,
  }).isRequired,
};
