import React from 'react';
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import { useLocation, useNavigate } from '@poly/client-routing';
import { insertParamsIntoURL } from '@poly/utils';

import { routes } from '../../routes.js';
import { InfinityList } from '../../components/InfinityList.js';
import { AssetListCard } from '../../components/AssetListCard.js';
import { EmptyAssetsListScreen } from '../EmptyAssetsListScreen.js';
import { BorderBottomContainer } from '../../components/Containers.js';
import { useProjectAssets } from './useProjectAssets.js';

export const ProjectDetailsTabWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 44px);
`;

const AssetItemWrapper = styled(BorderBottomContainer)`
  display: grid;
  background: #ffffff;
  box-sizing: border-box;
  grid-template-columns: 120px auto;
  gap: 16px;
  padding: 16px 24px;
`;

const EmptyAssetsTabS = styled(EmptyAssetsListScreen)`
  height: 100%;
`;

function AssetListItem({ asset }) {
  const navigate = useNavigate();
  const location = useLocation();

  const assetDetailUrl = insertParamsIntoURL(
    { assetId: asset._id },
    routes.assetDetail,
  );

  const onClick = () =>
    navigate(assetDetailUrl, { state: { previous: location } });

  return (
    <AssetItemWrapper onClick={onClick}>
      <AssetListCard asset={asset} />
    </AssetItemWrapper>
  );
}

AssetListItem.propTypes = {
  asset: shape({ _id: string.isRequired }).isRequired,
};

export function ProjectDetailsAssetsTab({ projectId }) {
  const { assets, total, loading, listProps } = useProjectAssets(projectId);

  if (loading) return null;

  if (total === 0) return <EmptyAssetsTabS />;

  return (
    <ProjectDetailsTabWrapperS>
      <InfinityList
        items={assets}
        entity="asset"
        ItemComponent={AssetListItem}
        {...listProps}
      />
    </ProjectDetailsTabWrapperS>
  );
}

ProjectDetailsAssetsTab.propTypes = { projectId: string.isRequired };
