import React from 'react';
import styled from 'styled-components';
import { Text } from '../components/Text.js';
import { FlexColumnContainer } from '../components/Containers.js';
import { ContactPhoneLink } from '../components/PhoneLink.js';
import { TextContainer } from './NotFoundScreen.js';

const Container = styled(FlexColumnContainer)`
  justify-content: space-between;
  height: 100%;
`;

export function InactivePropertyScreen() {
  return (
    <Container>
      <TextContainer>
        <Text size={18}>This asset is linked to an inactive location.</Text>
        <Text size={14}>For assistance, please contact support at</Text>
        <ContactPhoneLink />
      </TextContainer>
    </Container>
  );
}
