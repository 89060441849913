import * as R from 'ramda';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { shape, string } from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { getThemeColor, Loader } from '@poly/admin-book';
import { insertParamsIntoURL } from '@poly/utils';
import { Link } from '@poly/client-routing';

import { routes } from '../../routes.js';
import { LabelText } from '../../components/Text.js';
import { InfinityList } from '../../components/InfinityList.js';
import { NoResultsFoundScreen } from '../NoResultsFoundScreen.js';
import { screenListPropTypes } from '../../components/ScreenListWrapper/screenListPropTypes.js';
import { useSetListTotal } from '../../hooks/useSetListTotal.js';
import { useSearchProperties } from './useSearchProperties.js';

const PropertyNameS = styled.div`
  margin: 0 0 4px 0;
  font-size: ${R.prop('size')}px;
`;

const ManagerNameS = styled(LabelText)`
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
`;

const PropertyItemWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background: #ffffff;
  color: ${getThemeColor(['primaryDark'])};
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-decoration: none;
`;

const AssetsInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  min-width: 65px;
  max-width: 75px;
  padding: 4px 8px;
  background: rgba(71, 119, 216, 0.08);
`;

const AssetsInfoText = styled.p`
  margin: 0;
  color: #12347a;
  font-size: 12px;
  width: fit-content;
`;

function PropertyListItem({ property }) {
  const defaultSize = 18;

  const propertyNameRef = useRef(null);
  const [size, setSize] = useState(defaultSize);

  useEffect(() => {
    if (size === defaultSize) {
      const allowedHeight = property?.branchManager ? 65 : 85;
      const propertyNameHeight = propertyNameRef?.current?.offsetHeight || 60;

      const newSize = propertyNameHeight > allowedHeight ? 16 : 18;

      setSize(newSize);
    }
  }, []);

  const url = insertParamsIntoURL(
    { propertyId: property?._id },
    routes.propertyAssets,
  );

  return (
    <PropertyItemWrapper href={url}>
      <div>
        <PropertyNameS ref={propertyNameRef} size={size}>
          {property.name}
        </PropertyNameS>
        <ManagerNameS>{property?.branchManager?.fullName}</ManagerNameS>
      </div>
      <AssetsInfoWrapper>
        <AssetsInfoText>{property?.assetsCount} Assets</AssetsInfoText>
      </AssetsInfoWrapper>
    </PropertyItemWrapper>
  );
}

PropertyListItem.propTypes = {
  property: shape({ name: string.isRequired }),
};

export function PropertiesList({ sort, searchTerm, setTotal, entity }) {
  const isHistoryOpen = useSelector((state) => state.searchHistory.isOpen);

  const { loading, properties, total, listProps } = useSearchProperties(
    searchTerm,
    sort,
  );

  useSetListTotal(total, setTotal, loading);

  if (loading) return <Loader />;

  if (total === 0 && !isHistoryOpen) return <NoResultsFoundScreen />;

  return (
    <InfinityList
      entity={entity}
      items={properties}
      ItemComponent={PropertyListItem}
      {...listProps}
    />
  );
}

PropertiesList.propTypes = screenListPropTypes;
