import React from 'react';
import styled from 'styled-components';
import { string, func, bool, oneOfType } from 'prop-types';
import { getThemeColor } from '@poly/admin-book';

const AddNewEntityTextS = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
  color: ${getThemeColor(['secondaryDark'])};
`;

export function AddNewAssetEntity({ title, value, onChange, withNewMake }) {
  const onClick = () => onChange(!value);

  return !withNewMake ? (
    <AddNewEntityTextS onClick={onClick}>
      {value ? 'Cancel' : title}
    </AddNewEntityTextS>
  ) : null;
}

AddNewAssetEntity.propTypes = {
  title: string,
  onChange: func.isRequired,
  value: oneOfType([string, bool]),
  withNewMake: bool,
};
