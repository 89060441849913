import * as R from 'ramda';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { propEqLegacy } from '@poly/utils';

import { useNetworkStatus } from '../providers/NetworkStatusProvider.js';
import { assetScannerIndexedDb } from './indexedDb/indexedDb.js';
import { CachedEntitySyncStatuses } from './cache/helpers.js';
import { SET_SYNC_QUEUE_STATUS } from '../redux/queueSync/actions.js';
import { defaultSyncQueueStatusReducerState } from '../redux/queueSync/reducer.js';
import {
  subscribeIdbStoreChange,
  unsubscribeIdbStoreChange,
} from './indexedDb/indexedDbStoreChangeEvent.js';

export const QueueSyncStatuses = {
  PENDING: 'pending',
  SYNCING: 'syncing',
  FAILED: 'failed',
  DRAFT: 'draft',
};

export const changeSyncQueueStatusAction = (payload) => ({
  type: SET_SYNC_QUEUE_STATUS,
  payload,
});

export const getIdbEntityCountsByStoreNameP = async (storeName) => {
  const iDb = await assetScannerIndexedDb();

  const pendingCount = await iDb.getByIndexCount(
    storeName,
    'syncStatus',
    IDBKeyRange.only(CachedEntitySyncStatuses.PENDING),
  );

  const failedCount = await iDb.getByIndexCount(
    storeName,
    'syncStatus',
    IDBKeyRange.only(CachedEntitySyncStatuses.FAILED),
  );

  const draftCounts = await iDb.getByIndexCount(
    storeName,
    'syncStatus',
    IDBKeyRange.only(CachedEntitySyncStatuses.DRAFT),
  );

  return { pendingCount, failedCount, draftCounts };
};

// isNetworkStatusChangedToOnline :: { isOnline: Boolean, isWasOffline: Boolean } -> Boolean
const isNetworkStatusChangedToOnline = R.both(
  R.prop('isOnline'),
  R.prop('isWasOffline'),
);

// isFailedSyncCountWithoutPending :: { pendingCount: Int, failedCount: Int } -> Boolean
const isFailedSyncCountWithoutPending = R.both(
  propEqLegacy('pendingCount', 0),
  R.propSatisfies(R.gt(R.__, 0), 'failedCount'),
);

export const useEntitySyncStatusQueue = ({
  hasPermission,
  isAllowedTab,
  storeName,
}) => {
  const { isOnline, isWasOffline } = useNetworkStatus();

  const dispatch = useDispatch();

  const dispatchDraftStatus = (draftCounts) => {
    dispatch(
      changeSyncQueueStatusAction({
        status: draftCounts ? QueueSyncStatuses.DRAFT : null,
        itemsCount: draftCounts,
      }),
    );
  };

  const dispatchStatusIfBackToOnline = ({ pendingCount, failedCount }) => {
    if (isNetworkStatusChangedToOnline({ isOnline, isWasOffline })) {
      if (isFailedSyncCountWithoutPending({ pendingCount, failedCount })) {
        dispatch(
          changeSyncQueueStatusAction({
            status: QueueSyncStatuses.FAILED,
            itemsCount: failedCount,
          }),
        );
      } else if (pendingCount === 0) {
        dispatch(
          changeSyncQueueStatusAction(defaultSyncQueueStatusReducerState),
        );
      } else {
        dispatch(
          changeSyncQueueStatusAction({
            status: QueueSyncStatuses.SYNCING,
            itemsCount: pendingCount,
          }),
        );
      }
    }
  };

  useEffect(() => {
    const dispatchAssetsQueueStatusP = async () => {
      if (!hasPermission || !isAllowedTab) {
        return;
      }

      const { pendingCount, failedCount, draftCounts } =
        await getIdbEntityCountsByStoreNameP(storeName);

      if (isOnline && !pendingCount && !failedCount) {
        dispatchDraftStatus(draftCounts);
      }

      dispatchStatusIfBackToOnline({ pendingCount, failedCount });

      if (!isOnline) {
        dispatch(
          changeSyncQueueStatusAction({
            status: QueueSyncStatuses.PENDING,
            itemsCount: pendingCount,
          }),
        );
      }

      if (isOnline && !isWasOffline && failedCount > 0) {
        dispatch(
          changeSyncQueueStatusAction({
            status: QueueSyncStatuses.FAILED,
            itemsCount: failedCount,
          }),
        );
      }
    };

    dispatchAssetsQueueStatusP();

    subscribeIdbStoreChange(dispatchAssetsQueueStatusP);

    return () => unsubscribeIdbStoreChange(dispatchAssetsQueueStatusP);
  }, [isOnline, hasPermission, isAllowedTab, isWasOffline]);
};
