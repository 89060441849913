import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { getThemeColor } from '@poly/admin-book';
import { bool, func, number, string } from 'prop-types';
import { useNavigate } from '@poly/client-routing';

import { Icon } from '../icons/Icon.js';
import { routes } from '../routes.js';

const invertedBtnStyles = css`
  background-color: transparent;
  border: 1px solid ${getThemeColor(['secondaryDark'])};
  color: ${getThemeColor(['secondaryDark'])};
  svg {
    path {
      stroke: ${getThemeColor(['secondaryDark'])};
    }
  }
`;

const ButtonBase = styled.button`
  background: ${getThemeColor(['secondaryDark'])};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 20px;
  width: ${R.propOr('100%', 'width')};
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  justify-content: center;
  margin: 8px 0;
  border: none;

  &:focus {
    outline: 0;
  }

  svg {
    margin-right: 5px;

    path {
      stroke: #fff;
    }
  }

  opacity: ${({ disabled }) => disabled && 0.5};
  ${({ inverted }) => inverted && invertedBtnStyles}
`;

const IconWithMargin = styled(Icon)`
  margin-right: 5px;
`;

export function Button({
  width,
  iconName,
  disabled,
  loading,
  onClick,
  caption,
  type,
  form,
  className,
  inverted,
  iconSize,
}) {
  return (
    <ButtonBase
      form={form}
      type={type}
      width={width}
      disabled={disabled || loading}
      onClick={onClick}
      className={className}
      inverted={inverted}
    >
      {loading ? (
        'Loading...'
      ) : (
        <>
          {iconName && (
            <IconWithMargin
              name={iconName}
              width={iconSize}
              height={iconSize}
            />
          )}
          {caption}
        </>
      )}
    </ButtonBase>
  );
}

Button.propTypes = {
  iconName: string,
  className: string,
  caption: string.isRequired,
  disabled: bool,
  onClick: func,
  loading: bool,
  width: string,
  type: string,
  form: string,
  inverted: bool,
  iconSize: number,
};

Button.defaultProps = {
  iconSize: 14,
};

export const LinkButton = styled(ButtonBase.withComponent('a'))`
  text-decoration: none;
  box-sizing: border-box;
`;

export function GoHomeBtn({ inverted = true, caption = 'Go Home' }) {
  const navigate = useNavigate();
  const onClick = () => navigate(routes.home);
  return (
    <Button
      inverted={inverted}
      caption={caption}
      iconName="home"
      onClick={onClick}
    />
  );
}

GoHomeBtn.propTypes = {
  inverted: bool,
  caption: string,
};
