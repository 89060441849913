import * as R from 'ramda';
import styled from 'styled-components';
import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { instanceOf, oneOfType, shape, string, bool, number } from 'prop-types';
import { useLocation, useNavigate } from '@poly/client-routing';
import { insertParamsIntoURL, debounce } from '@poly/utils';

import {
  ASSET_PROJECTS_ITEM_ID,
  ASSET_DETAILS_IMAGE_ID,
  IMAGE_PLACEHOLDER_HEIGHT,
} from './constants.js';
import { routes } from '../../routes.js';
import { InfinityList } from '../../components/InfinityList.js';
import { ProjectListCard } from '../../components/ProjectListCard.js';
import { BorderBottomContainer } from '../../components/Containers.js';
import { useSearchProjects } from '../../hooks/useSearchProjects.js';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${R.prop('height')}px;

  > div > div > div {
    ${({ disabled }) => (disabled ? 'overflow-y: hidden !important' : '')};
  }
`;

function AssetProjectListItem({ project }) {
  const navigate = useNavigate();
  const location = useLocation();

  const projectDetailsUrl = insertParamsIntoURL(
    { projectId: project?._id },
    routes.projectDetail,
  );

  const onClick = () =>
    navigate(projectDetailsUrl, { state: { previous: location } });

  return (
    <BorderBottomContainer onClick={onClick} id={ASSET_PROJECTS_ITEM_ID}>
      <ProjectListCard project={project} />
    </BorderBottomContainer>
  );
}

AssetProjectListItem.propTypes = {
  project: shape({
    projectId: string.isRequired,
    startDate: oneOfType([string, instanceOf(Date)]),
    description: string,
    manager: shape({
      fullName: string,
    }),
  }),
};

export function AssetProjectsTab({ assetId, isScrollDisabled, wrapperHeight }) {
  const imageElement = document.getElementById(ASSET_DETAILS_IMAGE_ID);
  const imageHeight = imageElement
    ? imageElement.offsetHeight
    : IMAGE_PLACEHOLDER_HEIGHT;
  // 45px is a tabs headers height
  // change it if refactor tabs for the correct scroll work for all devices
  const emptySpaceHeight = wrapperHeight - (imageHeight + 45);

  const [height, setHeight] = useState(emptySpaceHeight);

  const query = useMemo(
    () => ({ must: { term: { assetIds: assetId } } }),
    [assetId],
  );

  const { projects, loading, listProps } = useSearchProjects(query);

  const debouncedGetElements = useCallback(
    debounce(200)(() => {
      const firstListItem = document.getElementById(ASSET_PROJECTS_ITEM_ID);

      if (!firstListItem) {
        debouncedGetElements();
      } else {
        const listHeight =
          firstListItem.parentNode.parentNode.parentNode.offsetHeight - 100;

        let finalHeight = emptySpaceHeight;

        if (listHeight > wrapperHeight) {
          finalHeight = wrapperHeight;
        } else if (
          listHeight > emptySpaceHeight &&
          listHeight < wrapperHeight
        ) {
          finalHeight = listHeight;
        }

        if (wrapperHeight !== height) {
          setHeight(finalHeight);
        }
      }
    }),
    [setHeight],
  );

  useEffect(() => {
    debouncedGetElements();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Wrapper disabled={isScrollDisabled} height={height}>
      <InfinityList
        items={projects}
        entity="project"
        ItemComponent={AssetProjectListItem}
        {...listProps}
      />
    </Wrapper>
  );
}

AssetProjectsTab.propTypes = {
  assetId: string.isRequired,
  wrapperHeight: number.isRequired,
  isScrollDisabled: bool.isRequired,
};
