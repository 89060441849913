import React from 'react';
import * as R from 'ramda';

import { insertParamsIntoURL, insertQueryParamsIntoURL } from '@poly/utils';
import { shape, string } from 'prop-types';
import { routes } from '../../routes.js';
import { usePreviewDetailsCache } from '../AssetForm/AssetPreview.js';
import { CachedEntitySyncStatuses } from '../../offline/cache/helpers.js';
import { assetsStoreName } from '../../offline/indexedDb/indexedDbStores.js';
import { EntityQueueItemBase } from '../../components/Queue/EntityQueueItemBase.js';

// getAssetManufacturerName :: {asset: Asset, manufacturer: String } -> String
const getAssetManufacturerName = R.compose(
  R.defaultTo(''),
  R.ifElse(
    R.prop('withNewMake'),
    R.path(['asset', 'newManufacturerName']),
    R.prop('manufacturer'),
  ),
);

const useAssetQueueDetails = (asset) => {
  const { syncStatus, manufacturerId, typeId, modelId, idbKey, qrCodeId } =
    asset;

  const { manufacturer, typeName } = usePreviewDetailsCache({
    manufacturerId,
    typeId,
    modelId,
  });

  const manufacturerName = getAssetManufacturerName({ asset, manufacturer });

  const isDraft = syncStatus === CachedEntitySyncStatuses.DRAFT;

  const assetName = `${typeName} ${manufacturerName} (${qrCodeId})`;

  return {
    syncStatus,
    qrCodeId,
    assetName,
    idbKey,
    isDraft,
  };
};

export function AssetQueueItem({ asset }) {
  const { assetName, idbKey, isDraft, qrCodeId, ...restAsset } =
    useAssetQueueDetails(asset);

  const assetsUrl = isDraft
    ? insertQueryParamsIntoURL({ idbKey, qrCodeId }, routes.addAsset)
    : insertParamsIntoURL({ assetIdbKey: idbKey }, routes.editAssetsQueue);

  return (
    <EntityQueueItemBase
      title={assetName}
      navigateUrl={assetsUrl}
      {...restAsset}
      indexedDbStoreName={assetsStoreName}
      idbKey={idbKey}
    />
  );
}

AssetQueueItem.propTypes = {
  asset: shape({ qrCodeId: string.isRequired, syncStatus: string.isRequired }),
};
