import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from '@poly/client-routing';
import { bool, func, shape } from 'prop-types';
import { useMutation } from '@apollo/client';
import { compressImage } from '@poly/client-utils/src/files/compressImage.js';

import { AssetFormFields } from '../AssetForm/AssetFormFields.js';
import {
  FormContainer,
  FormFooterContainer,
  FormS,
} from '../AssetForm/styles.js';
import { routes } from '../../routes.js';
import { Button } from '../../components/Button.js';
import { FormScreenContainer } from '../AssetForm/AddAssetScreen.js';
import { useAssetFromIndexedDb } from './useAssetFromIndexedDb.js';
import {
  addAssetMutation,
  formatCreateAssetMutationInput,
  validateAssetFormQRCode,
} from '../AssetForm/assetFormHelpers.js';
import { useNetworkStatus } from '../../providers/NetworkStatusProvider.js';
import { assetScannerIndexedDb } from '../../offline/indexedDb/indexedDb.js';
import { assetsStoreName } from '../../offline/indexedDb/indexedDbStores.js';
import { Loader } from '../ScanQrCode/Loader.js';
import { NoResultsFoundScreen } from '../NoResultsFoundScreen.js';
import { CachedEntitySyncStatuses } from '../../offline/cache/helpers.js';
import { publishIdbStoreChangedEvent } from '../../offline/indexedDb/indexedDbStoreChangeEvent.js';
import { useModalContext } from '../../providers/ModalProvider.js';
import { ErrorModal, assetQueueErrorModalId } from './ErrorModal.js';

const editAssetQueueFormId = 'editAssetQueueFormId';

function EditAssetQueueForm({ values, handleSubmit }) {
  const navigate = useNavigate();

  return (
    <FormS id={editAssetQueueFormId} onSubmit={handleSubmit}>
      <FormContainer
        title="Edit Asset Details"
        onClose={() => navigate(routes.assetsQueue)}
      >
        <AssetFormFields values={values} />
      </FormContainer>
    </FormS>
  );
}

EditAssetQueueForm.propTypes = {
  handleSubmit: func.isRequired,
  values: shape({ withNewMake: bool, withNewModel: bool }).isRequired,
};

export function EditAssetQueueScreen() {
  const { assetIdbKey } = useParams();

  const { openModal, closeModal } = useModalContext();

  const handleClose = () => closeModal(assetQueueErrorModalId);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [addAsset] = useMutation(addAssetMutation);

  const { asset, loading: assetLoading } = useAssetFromIndexedDb(assetIdbKey);

  const { isOnline } = useNetworkStatus();

  const handleSubmit = async (formData) => {
    const iDb = await assetScannerIndexedDb();

    const input = formatCreateAssetMutationInput(formData);

    try {
      setLoading(true);
      if (isOnline) {
        await addAsset({ variables: { input } });

        await iDb.update(
          assetsStoreName,
          {
            syncStatus: CachedEntitySyncStatuses.SUCCESS,
            errorMsg: null,
          },
          asset.idbKey,
        );
      } else {
        let compressedImage = null;

        if (formData?.photo && asset?.photo?.size !== formData.photo.size) {
          compressedImage = await compressImage(formData.photo);
        }

        await iDb.update(
          assetsStoreName,
          {
            ...formData,
            ...(compressedImage ? { photo: compressedImage } : {}),
          },
          asset.idbKey,
        );
      }
      setLoading(false);
      publishIdbStoreChangedEvent();
      navigate(routes.addAssetSuccess);
    } catch (error) {
      openModal({
        id: assetQueueErrorModalId,
        content: (
          <ErrorModal
            errorMsg={error.message}
            handleClose={handleClose}
            goHome={() => navigate(routes.home)}
          />
        ),
      });
    }
  };

  if (assetLoading) {
    return <Loader />;
  }

  if (!asset) {
    return <NoResultsFoundScreen />;
  }

  return (
    <FormScreenContainer>
      <Form
        onSubmit={handleSubmit}
        render={EditAssetQueueForm}
        initialValues={asset}
        validate={validateAssetFormQRCode}
      />
      <FormFooterContainer>
        <Button
          type="submit"
          iconName="retry"
          caption="Resubmit"
          form={editAssetQueueFormId}
          loading={loading}
        />
      </FormFooterContainer>
    </FormScreenContainer>
  );
}
