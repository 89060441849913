import * as R from 'ramda';
import { useCallback } from 'react';
import { gql, useSubscription } from '@apollo/client';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import { debounce, tryCallFunction } from '@poly/utils';
import {
  ELASTIC_SCORE_FIELD,
  DESC_SORT_ORDER,
  AssetStatuses,
} from '@poly/constants';

import { useStateInputForInfiniteListQuery } from '../../hooks/useStateInputForInfiniteListQuery.js';

const projectAssetsQuery = gql`
  query projectAssetsQuery($projectId: ID, $input: CollectionSearchParams!) {
    project(projectId: $projectId) {
      _id
      searchAssets(input: $input) {
        hits {
          _id
          type {
            _id
            name
          }
          qrCodeId
          photo {
            url
          }
          modelDoc {
            _id
            name
          }
          manufacturerDoc {
            _id
            name
          }
        }
        total
      }
    }
  }
`;

const projectAssetsSubscription = gql`
  subscription projectAssetsSubscription($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      type
    }
  }
`;

const assetsSubscription = gql`
  subscription assetsSubscription($input: CollectionSearchParams!) {
    searchAssetChanged(input: $input) {
      id
      type
    }
  }
`;

export const useProjectAssets = (projectId) => {
  const sort = [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD];

  const input = useStateInputForInfiniteListQuery(sort, '', {
    bool: { must: { match: { status: AssetStatuses.ACTIVE } } },
  });

  const {
    data,
    loading,
    refetch,
    tableProps: listProps,
  } = useTableInfiniteScrollQuery(projectAssetsQuery, input, {
    endpointName: ['project', 'searchAssets'],
    variables: { projectId },
    pageSize: 25,
  });

  const debouncedRefetch = useCallback(
    debounce(2000)(() => tryCallFunction(refetch)()),
    [refetch],
  );

  useSubscription(assetsSubscription, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: { input: {} },
  });

  useSubscription(projectAssetsSubscription, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: { input: { projectId } },
  });

  const assets = R.pathOr([], ['project', 'searchAssets', 'hits'], data);

  const total = R.pathOr(0, ['project', 'searchAssets', 'total'], data);

  return { total, assets, loading, listProps };
};
