import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';

import { useStateInputForInfiniteListQuery } from '../../hooks/useStateInputForInfiniteListQuery.js';
import { getSearchPropertiesQuery } from '../../components/PropertySelect.js';

const SEARCH_PROPERTIES_LIST = gql`
  query SEARCH_PROPERTIES_LIST($input: CollectionSearchParams!) {
    searchProperties(input: $input) {
      hits {
        _id
        name
        assetsCount
        branchManager {
          _id
          fullName
        }
      }
      total
    }
  }
`;

export const useSearchProperties = (searchTerm, sort) => {
  const clientId = useSelector((state) => state.globalClient?.clientId);

  const query = getSearchPropertiesQuery(clientId);

  const input = useStateInputForInfiniteListQuery(sort, searchTerm, query);

  const {
    data,
    loading,
    tableProps: listProps,
  } = useTableInfiniteScrollQuery(SEARCH_PROPERTIES_LIST, input, {
    endpointName: 'searchProperties',
    pageSize: 25,
  });

  const properties = R.pathOr([], ['searchProperties', 'hits'], data);

  const total = R.pathOr(0, ['searchProperties', 'total'], data);

  return {
    loading,
    properties,
    total,
    listProps,
  };
};
