import React from 'react';
import { useNavigate, useRouterQuery } from '@poly/client-routing';

import { Text } from '../../components/Text.js';
import {
  FlexColumnCentered,
  FlexColumnSpaceBetween,
} from '../../components/Containers.js';
import { ErrorScreen } from '../StatusScreen.js';
import { PhoneButtonLink } from '../../components/PhoneLink.js';
import { Button } from '../../components/Button.js';

export function ScanQrCodeErrorScreen() {
  const { isExists } = useRouterQuery(['isExists']);

  const navigate = useNavigate();
  return (
    <ErrorScreen>
      <FlexColumnSpaceBetween>
        <FlexColumnCentered>
          <Text size={22} color="primaryRegular">
            {isExists ? 'Code already exists' : 'Code is not correct'}
          </Text>
          <Text size={14} align="center" margin="12px 0">
            {/* eslint-disable-next-line @cspell/spellchecker */}
            {!isExists &&
              "We're sorry, but the code you are entering is not correct. Please reach us by phone."}
          </Text>
        </FlexColumnCentered>
        <FlexColumnCentered>
          <PhoneButtonLink inverted={false} />
          <Button inverted caption="Try again" onClick={() => navigate(-1)} />
        </FlexColumnCentered>
      </FlexColumnSpaceBetween>
    </ErrorScreen>
  );
}
