import * as R from 'ramda';
import { ensureIsDate } from '@poly/utils';
import { differenceInDays } from 'date-fns';

export const STORAGE_CACHE_DATE_KEY = 'storageCachedAt';

export const CachedEntitySyncStatuses = {
  PENDING: 'pending',
  FAILED: 'failed',
  SUCCESS: 'success',
  DRAFT: 'draft',
};

const CACHE_DAYS = 7;

// isCacheObsolete :: {value: Date} -> Boolean
export const isCacheObsolete = R.compose(
  R.ifElse(
    R.identity,
    R.compose(R.gt(R.__, CACHE_DAYS), (date) =>
      differenceInDays(Date.now(), ensureIsDate(date)),
    ),
    R.T,
  ),
  R.prop('value'),
);
