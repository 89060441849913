import React from 'react';
import { string, func } from 'prop-types';
import { WorkOrderStatus } from '@poly/constants';

import { Select } from './Select/Select.js';

const ProjectStatusOptions = [
  { value: WorkOrderStatus.ACTIVE, label: 'Active' },
  { value: WorkOrderStatus.PENDING, label: 'Pending' },
  { value: WorkOrderStatus.ON_HOLD_PROJECT, label: 'On hold project' },
  { value: WorkOrderStatus.ON_HOLD_CLIENT, label: 'On hold client' },
  { value: WorkOrderStatus.ON_HOLD_PARTS, label: 'On hold parts' },
  { value: WorkOrderStatus.COMPLETED, label: 'Completed' },
];

export function ProjectStatusSelect({ value, onChange }) {
  return (
    <Select
      isClearable
      value={value}
      onChange={onChange}
      placeholder="Select Status"
      options={ProjectStatusOptions}
    />
  );
}

ProjectStatusSelect.propTypes = {
  value: string,
  onChange: func.isRequired,
};
