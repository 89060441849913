import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, element, func, string } from 'prop-types';
import { useNavigate } from '@poly/client-routing';

import { ScreenTitle } from './ScreenTitle.js';
import { Icon } from '../icons/Icon.js';

const HeaderWrapper = styled.div`
  display: flex;
  padding: 26px 26px 16px 26px;
`;

const ToolBarWrapper = styled.div`
  margin: 5px 0 0 10px;
`;

const TitleS = styled(ScreenTitle)`
  margin: 0;
  width: 100%;
`;

const IconGoBackS = styled(Icon)`
  margin: 7px 15px 0 0;
  cursor: pointer;
`;

function GoBackButton({ route, onClickBack }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (R.is(Function, onClickBack)) {
      onClickBack();
    } else {
      navigate(route || -1);
    }
  };

  return <IconGoBackS onClick={handleClick} name="goBack" />;
}

GoBackButton.propTypes = { route: string, onClickBack: func };

export function ScreenHeader({
  title,
  route,
  goBack,
  toolBar,
  className,
  onClickBack,
}) {
  return (
    <HeaderWrapper id="screen-header" className={className}>
      {goBack && <GoBackButton route={route} onClickBack={onClickBack} />}
      <TitleS>{title}</TitleS>
      {toolBar && <ToolBarWrapper>{toolBar}</ToolBarWrapper>}
    </HeaderWrapper>
  );
}

ScreenHeader.propTypes = {
  title: string.isRequired,
  onClickBack: func,
  className: string,
  toolBar: element,
  route: string,
  goBack: bool,
};
