import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { instanceOf, oneOfType, shape, string } from 'prop-types';
import { pathOrNothingUI } from '@poly/client-utils';
import { getThemeColor } from '@poly/admin-book';
import { formatDate } from '@poly/utils';

import { ProjectStatus } from './ProjectStatus.js';
import { BlockWithLabel } from './BlockWithLabel.js';
import { ProjectPriority } from './ProjectPriority.js';
import { FlexColumnContainer, FlexContainer } from './Containers.js';

// getProjectStartDate :: Project -> String
const getProjectStartDate = R.compose(formatDate, R.prop('startDate'));

const ProjectNumberText = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: ${getThemeColor(['primaryDark2'])};
`;

const SpaceBetweenContainer = styled(FlexContainer)`
  justify-content: space-between;
  padding-bottom: 18px;
  align-items: center;
`;

const FlexContainerS = styled(FlexContainer)`
  gap: ${R.prop('gap')};
`;

export function ProjectListCard({ project }) {
  return (
    <FlexColumnContainer>
      <SpaceBetweenContainer>
        <ProjectNumberText>{project.projectId}</ProjectNumberText>
        <FlexContainerS gap="10px">
          <ProjectPriority priority={project.priority} />
          <ProjectStatus status={project.status} />
        </FlexContainerS>
      </SpaceBetweenContainer>

      <FlexContainerS gap="70px">
        <BlockWithLabel
          label="Start date"
          value={getProjectStartDate(project)}
        />
        <BlockWithLabel
          label="Client Service Manager"
          value={pathOrNothingUI(['manager', 'fullName'], project)}
        />
      </FlexContainerS>

      <BlockWithLabel
        label="Description"
        value={pathOrNothingUI(['description'], project)}
      />
    </FlexColumnContainer>
  );
}

ProjectListCard.propTypes = {
  project: shape({
    description: string,
    projectId: string.isRequired,
    manager: shape({ fullName: string }),
    startDate: oneOfType([string, instanceOf(Date)]),
  }),
};
