import React from 'react';
import { shape, string } from 'prop-types';
import { insertQueryParamsIntoURL } from '@poly/utils/src/url.js';

import { EntitiesQueueScreenBase } from '../AssetsQueueScreen/EntitiesQueueScreenBase.js';
import { useIndexedDbStore } from '../../hooks/useIndexedDbStore.js';
import { assetProceduresStoreName } from '../../offline/indexedDb/indexedDbStores.js';
import { EntityQueueItemBase } from '../../components/Queue/EntityQueueItemBase.js';
import { routes } from '../../routes.js';

function AssetsProceduresQueueItem({ assetProcedure }) {
  const assetsUrl = insertQueryParamsIntoURL(
    { maintenanceProcedureId: assetProcedure?.idbKey },
    routes.assetProcedureSteps,
  );

  return (
    <EntityQueueItemBase
      title={assetProcedure.projectId}
      navigateUrl={assetsUrl}
      {...assetProcedure}
      indexedDbStoreName={assetProceduresStoreName}
    />
  );
}

AssetsProceduresQueueItem.propTypes = {
  assetProcedure: shape({
    procedureId: string.isRequired,
    projectId: string.isRequired,
  }),
};

export function AssetsProceduresQueueScreen() {
  const { data, ...restProps } = useIndexedDbStore(assetProceduresStoreName);
  return (
    <EntitiesQueueScreenBase
      {...restProps}
      entities={data}
      itemKey="idbKey"
      entityName="assetProcedure"
      QueueItemComponent={AssetsProceduresQueueItem}
      indexedDbStoreName={assetProceduresStoreName}
    />
  );
}
