import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SET_GLOBAL_CLIENT } from '../redux/currentClient/actions.js';
import { GLOBAL_CLIENT_SELECTED_ID_KEY } from '../components/GlobalClientSelect.js';

const setGlobalClientAction = (clientId) => ({
  type: SET_GLOBAL_CLIENT,
  payload: { clientId },
});

export const useGlobalClient = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const clientId = localStorage.getItem(GLOBAL_CLIENT_SELECTED_ID_KEY);
    dispatch(setGlobalClientAction(clientId));
  }, []);
};
