import * as R from 'ramda';
import styled from 'styled-components';
import { Html5Qrcode, Html5QrcodeScannerState } from 'html5-qrcode';
import React, { useEffect, useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';

import {
  scannerContainerId,
  QR_CODE_SCANNER_STATUSES,
  QR_CODE_SCANNER_STATUSES_COLORS,
} from './common.js';
import { Icon } from '../../icons/Icon.js';
import { FlexColumnContainer } from '../../components/Containers.js';

const calculateScanRegionSize = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const minEdgePercentage = 0.7;
  const minEdgeSize = Math.min(width, height);
  return Math.floor(minEdgeSize * minEdgePercentage);
};

const calculateAspectRatio = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  return width > height ? width / height : height / width;
};

const SCANNER_BORDER_DEFAULT_COLOR = '#fff';

const getScannerBorderColor = R.propOr(
  SCANNER_BORDER_DEFAULT_COLOR,
  R.__,
  QR_CODE_SCANNER_STATUSES_COLORS,
);

const ScannerContainer = styled.div.attrs({
  id: scannerContainerId,
})`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  video {
    height: 100%;
    width: 100%;
  }
  #qr-shaded-region {
    display: ${({ showBox }) => !showBox && 'none'};
    position: absolute !important;
    top: ${({ boxSize }) => `calc(50% - ${boxSize / 2}px)`} !important;
    left: ${({ boxSize }) => `calc(50% - ${boxSize / 2}px)`} !important;
    width: ${R.prop('boxSize')}px !important;
    height: ${R.prop('boxSize')}px !important;
    border-style: dotted !important;
    border-width: 2px !important;
    border-color: ${({ scannerStatus }) =>
      getScannerBorderColor(scannerStatus)} !important;
    border-radius: 8px;
    z-index: 3;
    div {
      display: none;
    }
  }
`;

function isTorchSupported(scanner) {
  const settings = scanner.getRunningTrackSettings();
  return 'torch' in settings;
}

const LightBtnContainer = styled.div`
  width: 68px;
  height: 68px;
  position: absolute;
  bottom: 32px;
  z-index: 3;
`;

const TextContainer = styled(FlexColumnContainer)`
  position: absolute;
  top: 100px;
  z-index: 2;
  text-align: center;
`;

function LightBtn({ scanner }) {
  const toggleLight = async () => {
    const { torch } = scanner.getRunningTrackSettings();
    const constraints = {
      torch: !torch,
      advanced: [{ torch: !torch }],
    };

    await scanner.applyVideoConstraints(constraints);
  };
  return (
    isTorchSupported(scanner) && (
      <LightBtnContainer onClick={toggleLight}>
        <Icon name="light" />
      </LightBtnContainer>
    )
  );
}

LightBtn.propTypes = {
  scanner: shape({
    getRunningTrackSettings: func.isRequired,
    applyVideoConstraints: func.isRequired,
  }),
};

// isScannerInState :: ScannerState -> Scanner -> Boolean
// ScannerState = Number
// Scanner = { getState: _ -> Number }
const isScannerInState = (state) =>
  R.unless(R.isNil, R.compose(R.equals(state), R.invoker(0, 'getState')));

// isScannerActive :: Scanner -> Boolean
// eslint-disable-next-line @cspell/spellchecker
const isScannerActive = isScannerInState(Html5QrcodeScannerState.SCANNING);

// isScannerPaused :: Scanner -> Boolean
// eslint-disable-next-line @cspell/spellchecker
const isScannerPaused = isScannerInState(Html5QrcodeScannerState.PAUSED);

const ScannerTitle = styled.span`
  color: #fff;
  font-size: 18px;
  margin: 24px 0 12px 0;
`;

const ScannerSubtitle = styled.span`
  color: #fff;
  font-size: 14px;
  margin: 0 0 12px 0;
`;

const ScannerStatusText = styled.span`
  font-size: 16px;
  margin-bottom: 12px;
  color: ${({ scannerStatus }) =>
    QR_CODE_SCANNER_STATUSES_COLORS[scannerStatus]};
`;

const SCANNER_STATUS_TEXT = {
  [QR_CODE_SCANNER_STATUSES.new]: 'We found new QR Code',
  [QR_CODE_SCANNER_STATUSES.found]: 'QR Code Found',
  [QR_CODE_SCANNER_STATUSES.incorrect]:
    'QR Code is not correct, please try again',
};

function StatusMessage({ scannerStatus }) {
  return (
    <ScannerStatusText scannerStatus={scannerStatus}>
      {SCANNER_STATUS_TEXT[scannerStatus]}
    </ScannerStatusText>
  );
}

StatusMessage.propTypes = {
  scannerStatus: string.isRequired,
};

function ScannerText() {
  return (
    <>
      <ScannerTitle>Scan QR Code</ScannerTitle>
      <ScannerSubtitle>Scan the QR Code on the equipment</ScannerSubtitle>
    </>
  );
}

function ScannerTextWithStatusMsg({ status }) {
  return (
    <TextContainer>
      <ScannerText />
      {status && <StatusMessage scannerStatus={status} />}
    </TextContainer>
  );
}

ScannerTextWithStatusMsg.propTypes = {
  status: string,
};

export function Scanner({
  onClose,
  status,
  setScanText,
  setIsDisabled,
  pause,
}) {
  const scannerRef = useRef();

  const boxSize = calculateScanRegionSize();

  const isActive = isScannerActive(scannerRef.current);
  const isPaused = isScannerPaused(scannerRef.current);

  useEffect(() => {
    if (isActive && pause) {
      scannerRef.current.pause();
    }
    if (isPaused && !pause) {
      scannerRef.current.resume();
    }
  }, [pause]);

  useEffect(() => {
    const qrCodeScanner = new Html5Qrcode(scannerContainerId, false);
    const aspectRatio = calculateAspectRatio();

    const startScanner = async () => {
      try {
        await qrCodeScanner.start(
          {},
          {
            // eslint-disable-next-line @cspell/spellchecker
            qrbox: { width: boxSize, height: boxSize },
            fps: 20,
            disableFlip: true,
            videoConstraints: {
              facingMode: 'environment',
              height: window.innerWidth,
              width: window.innerHeight,
            },
            aspectRatio,
          },
          async (text) => {
            if (text) {
              setScanText(text);
            }
          },
          () => {},
        );
        scannerRef.current = qrCodeScanner;
        setIsDisabled(false);
      } catch (err) {
        onClose(err);
        setIsDisabled(false);
      }
    };

    startScanner();

    return async () => {
      if (isScannerActive(scannerRef.current)) {
        await qrCodeScanner.stop();
        qrCodeScanner.clear();
      }
    };
  }, []);
  return (
    <>
      {!pause && <ScannerTextWithStatusMsg status={status} />}
      <ScannerContainer
        scannerStatus={status}
        boxSize={boxSize}
        showBox={!pause}
      >
        {scannerRef.current && <LightBtn scanner={scannerRef.current} />}
      </ScannerContainer>
    </>
  );
}

Scanner.propTypes = {
  pause: bool,
  status: string,
  onClose: func.isRequired,
  setScanText: func.isRequired,
  setIsDisabled: func.isRequired,
};
