import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { getThemeColor } from '@poly/admin-book';

import { DarkText } from '../Text.js';
import { SortSelect } from '../SortSelect.js';

const SortingWithTotalWrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 0 24px;
`;

const TotalTextS = styled(DarkText)`
  color: ${getThemeColor(['primaryLight2'])};
`;

export function SortingWithTotal({ totalTitle, ...sortingProps }) {
  return (
    <SortingWithTotalWrapperS>
      <TotalTextS>{totalTitle}</TotalTextS>
      <SortSelect {...sortingProps} />
    </SortingWithTotalWrapperS>
  );
}

SortingWithTotal.propTypes = { totalTitle: string.isRequired };
