import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { insertParamsIntoURL } from '@poly/utils';
import { useNavigate, useParams } from '@poly/client-routing';
import styled from 'styled-components';
import { arrayOf, func, shape, string } from 'prop-types';

import { Text } from '../../components/Text.js';
import { FlexColumnCentered } from '../../components/Containers.js';
import { AssetDetailsContainer } from '../AssetDetailsContainer.js';
import { Selector } from '../../components/Selector.js';
import { Button } from '../../components/Button.js';
import { routes } from '../../routes.js';
import { useAssetCheckInProjectsQuery } from './useAssetCheckInProjectsQuery.js';
import { Redirect } from '../../components/Navigation.js';
import { CreateWorkOrderButton } from './CreateWorkOrderButton.js';

const ContentContainer = styled(FlexColumnCentered)`
  justify-content: flex-start;
  ${({ withPadding }) => withPadding && 'margin: 0 40px'};
`;

const SelectorContainer = styled(FlexColumnCentered)`
  margin-top: 20px;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
`;

function CheckInButton({ projectId }) {
  const { assetQrCodeId } = useParams();
  const navigate = useNavigate();

  const onClick = () =>
    navigate(
      insertParamsIntoURL(
        { assetQrCodeId, projectId },
        routes.supplierCheckInConfirm,
      ),
    );
  return (
    <Button
      onClick={onClick}
      iconName="complete"
      caption="Supplier Onsite Check-in"
    />
  );
}

CheckInButton.propTypes = {
  projectId: string,
};

const getProjectOptions = R.map(
  R.applySpec({ id: R.prop('projectId'), title: R.prop('projectId') }),
);

function ProjectsSelector({
  projects,
  selectedProjectId,
  setSelectedProjectId,
}) {
  const options = getProjectOptions(projects);
  return (
    <SelectorContainer>
      <Selector
        optionWidth="100%"
        optionHeight="50px"
        value={selectedProjectId}
        onClick={setSelectedProjectId}
        options={options}
      />
    </SelectorContainer>
  );
}

const assetProjectPropTypes = shape({
  projectId: string.isRequired,
});

ProjectsSelector.propTypes = {
  projects: arrayOf(assetProjectPropTypes),
  selectedProjectId: string,
  setSelectedProjectId: func.isRequired,
};

// getAssetProjectsToCheckIn :: AssetScannerProject -> String
const getProjectId = R.prop('projectId');

// getAssetProjectsToCheckIn :: Asset -> [AssetScannerProject]
const getAssetProjectsToCheckIn = R.pathOr([], ['checkInProjects']);

export function SupplierCheckInSelectProjectScreen() {
  const { assetQrCodeId } = useParams();
  const [selectedProjectId, setSelectedProjectId] = useState();

  const { asset, loading } = useAssetCheckInProjectsQuery();

  const projectsToCheckIn = getAssetProjectsToCheckIn(asset);
  const firstCheckInProject = projectsToCheckIn[0];
  const firstCheckInProjectId = getProjectId(firstCheckInProject);

  useEffect(() => {
    if (!selectedProjectId && projectsToCheckIn.length > 0) {
      setSelectedProjectId(firstCheckInProjectId);
    }
  }, [projectsToCheckIn]);

  if (loading) {
    return 'Loading...';
  }

  if (projectsToCheckIn.length === 0) {
    return <Redirect route={routes.notFoundProject} />;
  }

  if (projectsToCheckIn.length === 1) {
    const redirectUrl = insertParamsIntoURL(
      { assetQrCodeId, projectId: firstCheckInProjectId },
      routes.supplierCheckInConfirm,
    );

    return <Redirect route={redirectUrl} />;
  }

  return (
    <AssetDetailsContainer asset={asset}>
      <>
        <ContentContainer withPadding={projectsToCheckIn.length === 1}>
          <Text size={24} margin="0 0 12px 0">
            Select Project
          </Text>
          <Text size={14} align="center">
            There seems to be multiple projects open for this asset which
            project are you checking in for?
          </Text>
          <ProjectsSelector
            projects={projectsToCheckIn}
            selectedProjectId={selectedProjectId}
            setSelectedProjectId={setSelectedProjectId}
          />
        </ContentContainer>
        <ButtonsWrapper>
          <CreateWorkOrderButton
            assetQrCodeId={assetQrCodeId}
            projectId={selectedProjectId}
          />
          <CheckInButton projectId={selectedProjectId} />
        </ButtonsWrapper>
      </>
    </AssetDetailsContainer>
  );
}
