import * as R from 'ramda';
import { gql } from '@apollo/client';
import { isNilOrEmpty, renameProp, assocBy } from '@poly/utils';

export const executeProcedureMutation = gql`
  mutation executeProcedureMutation($input: ExecuteProcedureInput!) {
    executeProcedure(input: $input) {
      _id
    }
  }
`;

// prepareExecutedProcedureInput :: FormData -> ExecuteProcedureInput
export const prepareExecutedProcedureInput = R.compose(
  R.over(
    R.lensProp('steps'),
    R.map(
      R.compose(
        R.ifElse(
          R.propSatisfies(isNilOrEmpty, 'attachments'),
          R.dissoc('attachments'),
          assocBy(
            'attachments',
            R.compose(
              R.map(
                R.applySpec({ upload: R.identity, fileName: R.prop('name') }),
              ),
              R.prop('attachments'),
            ),
          ),
        ),
        R.pick(['comment', 'status', 'attachments', 'description', 'name']),
      ),
    ),
  ),
  renameProp('supplierId', 'completedBy'),
  renameProp('procedureId', 'relatedProcedureId'),
  R.pick(['assetId', 'projectId', 'procedureId', 'supplierId', 'steps']),
);
