import * as R from 'ramda';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import React, { useEffect } from 'react';
import { bool, func, shape } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from '@poly/client-routing';

import { routes } from '../../routes.js';
import { projectFilterFormId } from './constants.js';
import { ProjectSortingOptions } from '../../constants/sorting.js';
import { PropertySelect } from '../../components/PropertySelect.js';
import { SET_PROJECTS_FILTER } from '../../redux/project/actions.js';
import { Select } from '../../components/Select/Select.js';
import { FormFieldWithLabel } from '../../components/FormFieldWithLabel.js';
import { defaultProjectsFilterState } from '../../redux/project/reducer.js';
import { ProjectStatusSelect } from '../../components/ProjectStatusSelect.js';
import { ProjectPrioritySelect } from '../../components/ProjectPrioritySelect.js';

const ProjectFilterFormS = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
`;

function ProjectFilterPropertySelect(props) {
  return <PropertySelect {...props} disableIfSingle />;
}

function ProjectFilterSortBySelect(props) {
  return (
    <Select
      {...props}
      placeholder="Select Status"
      options={ProjectSortingOptions}
    />
  );
}

function ProjectFilterFormRendered({
  form,
  handleSubmit,
  triggerReset,
  setResetTrigger,
}) {
  useEffect(() => {
    if (triggerReset) {
      form.reset(defaultProjectsFilterState);
      setResetTrigger(false);
    }
  }, [triggerReset]);

  return (
    <ProjectFilterFormS id={projectFilterFormId} onSubmit={handleSubmit}>
      <FormFieldWithLabel
        label="Sort by"
        name="sortingOption"
        Component={ProjectFilterSortBySelect}
      />
      <FormFieldWithLabel
        label="Status"
        name="status"
        Component={ProjectStatusSelect}
      />
      <FormFieldWithLabel
        label="Priority"
        name="priority"
        Component={ProjectPrioritySelect}
      />
      <FormFieldWithLabel
        label="Property"
        name="propertyId"
        Component={ProjectFilterPropertySelect}
      />
    </ProjectFilterFormS>
  );
}

ProjectFilterFormRendered.propTypes = {
  triggerReset: bool.isRequired,
  handleSubmit: func.isRequired,
  setResetTrigger: func.isRequired,
  form: shape({ reset: func.isRequired }).isRequired,
};

// prepareProjectsFilterPayload :: FormData -> ProjectsFilterPayload
const prepareProjectsFilterPayload = R.mergeDeepRight(
  defaultProjectsFilterState,
);

export function ProjectFilterForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const initialValues = useSelector(R.prop('searchProjectsFilter'));

  const onSubmit = (formData) => {
    dispatch({
      type: SET_PROJECTS_FILTER,
      payload: prepareProjectsFilterPayload(formData),
    });
    navigate(routes.projects, { state: { previous: location } });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={ProjectFilterFormRendered}
      {...props}
    />
  );
}
