import React from 'react';
import { string } from 'prop-types';
import { WorkOrderPriority } from '@poly/constants';

import { Icon } from '../icons/Icon.js';
import { ColorTextS, StatusContainerS } from './ProjectStatus.js';

const projectPriorityConfig = {
  [WorkOrderPriority.NORMAL]: {
    color: '#5C9819',
    background: '#d8eac3',
    text: 'Normal',
    icon: 'clock',
  },
  [WorkOrderPriority.URGENT]: {
    color: '#D19C19',
    background: '#FFEEC4',
    text: 'Urgent',
    icon: 'clock',
  },
  [WorkOrderPriority.EMERGENCY]: {
    color: '#E75858',
    background: '#FFEEEA',
    text: 'Emergency',
    icon: 'clock',
  },
  [WorkOrderPriority.CUSTOM]: {
    color: '#12347A',
    background: '#f0f4fc',
    text: 'Custom',
    icon: 'wrench',
  },
  [WorkOrderPriority.PLANNED]: {
    color: '#8599F0',
    background: '#edf0fd',
    text: 'Planned',
    icon: 'clockHistory',
  },
};

export function ProjectPriority({ priority }) {
  const config = projectPriorityConfig[priority];

  if (!config) {
    return null;
  }

  const { color, text, background, icon } = config;

  return (
    <StatusContainerS background={background}>
      <Icon name={icon} color={color} />
      <ColorTextS color={color}>{text}</ColorTextS>
    </StatusContainerS>
  );
}

ProjectPriority.propTypes = { priority: string.isRequired };
